import { SharedModule } from '@vendure/admin-ui/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { JofemarNuukCollectionService } from '../../../services/jofemar-nuuk/jofemar-nuuk-collection.service';
import { JofemarNuukDetailFormSettingComponent } from './jofemar-nuuk-detail-form-setting/jofemar-nuuk-detail-form-setting';

@Component({
    selector: 'jofemar-nuuk-settings',
    templateUrl: './jofemar-nuuk-settings.component.html',
    styleUrls: ['./jofemar-nuuk-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule, JofemarNuukDetailFormSettingComponent],
})
export class JofemarNuukSettingsComponent {
    jofemarNuukCollection$ = this.jofemarNuukCollectionService.jofemarNuukCollection$;

    constructor(private readonly jofemarNuukCollectionService: JofemarNuukCollectionService) {}
}
