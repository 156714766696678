import { SharedModule } from '@vendure/admin-ui/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { JofemarVisionEsPlusCollectionService } from '../../../services/jofemar-vision-es-plus/jofemar-vision-es-plus-collection.service';
import { JofemarVisionEsPlusDetailFormSettingComponent } from './jofemar-vision-es-plus-detail-form-setting/jofemar-vision-es-plus-detail-form-setting';

@Component({
    selector: 'jofemar-vision-es-plus-settings',
    templateUrl: './jofemar-vision-es-plus-settings.component.html',
    styleUrls: ['./jofemar-vision-es-plus-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule, JofemarVisionEsPlusDetailFormSettingComponent],
})
export class JofemarVisionEsPlusSettingsComponent {
    jofemarVisionEsPlusCollection$ = this.jofemarVisionEsPlusCollectionService.jofemarVisionEsPlusCollection$;

    constructor(
        private readonly jofemarVisionEsPlusCollectionService: JofemarVisionEsPlusCollectionService,
    ) {}
}
