import { restartProductModule } from './restart-product-module';
import { restartJofemarVisionEsPlusModule } from './restart-jofemar-vision-es-plus-module';
import { restartJofemarNuukModule } from './restart-jofemar-nuuk-module';
import { registerBulkAction } from '@vendure/admin-ui/core';
import { Locations } from '../constants';

export const restartModules = [
    registerBulkAction({
        location: Locations.milktapModulesList,
        label: 'agromaat.bulk.restart-product-modules',
        icon: 'refresh',
        onClick: restartProductModule,
    }),
    registerBulkAction({
        location: Locations.vendingModulesList,
        label: 'agromaat.bulk.restart-product-modules',
        icon: 'refresh',
        onClick: restartProductModule,
    }),
    registerBulkAction({
        location: Locations.jofemarVisionEsPlusModulesList,
        label: 'agromaat.bulk.restart-jofemars',
        icon: 'refresh',
        onClick: restartJofemarVisionEsPlusModule,
    }),
    registerBulkAction({
        location: Locations.jofemarNuukModulesList,
        label: 'agromaat.bulk.restart-jofemars',
        icon: 'refresh',
        onClick: restartJofemarNuukModule,
    }),
];
