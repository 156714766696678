import { measureJofemarVisionEsPlusModule } from './measure-jofemar-vision-es-plus-module';
import { measureProductModule } from './measure-product-module';
import { registerBulkAction } from '@vendure/admin-ui/core';
import { measureJofemarNuukModule } from './measure-jofemar-nuuk-module';
import { Locations } from '../constants';

export const measureModules = [
    registerBulkAction({
        location: Locations.milktapModulesList,
        label: 'agromaat.bulk.measure-product-modules',
        icon: 'cpu',
        onClick: measureProductModule,
    }),
    registerBulkAction({
        location: Locations.vendingModulesList,
        label: 'agromaat.bulk.measure-product-modules',
        icon: 'cpu',
        onClick: measureProductModule,
    }),
    registerBulkAction({
        location: Locations.jofemarVisionEsPlusModulesList,
        label: 'agromaat.bulk.measure-jofemar-vision-es-plus',
        icon: 'cpu',
        onClick: measureJofemarVisionEsPlusModule,
    }),
    registerBulkAction({
        location: Locations.jofemarNuukModulesList,
        label: 'agromaat.bulk.measure-jofemar-nuuk',
        icon: 'cpu',
        onClick: measureJofemarNuukModule,
    }),
];
