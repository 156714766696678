import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule, TypedBaseListComponent } from '@vendure/admin-ui/core';
import { DeviceDropdownComponent } from '../../support/device-dropdown/device-dropdown.component';
import { JofemarNuukCollectionService } from '../../../services/jofemar-nuuk/jofemar-nuuk-collection.service';
import { GetJofemarNuukChannelListDocument, JofemarNuukFragment } from '../../../generated-types';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'jofemar-nuuk-list',
    templateUrl: './jofemar-nuuk-list.component.html',
    styleUrls: ['./jofemar-nuuk-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule, DeviceDropdownComponent],
})
export class JofemarNuukListComponent extends TypedBaseListComponent<
    typeof GetJofemarNuukChannelListDocument,
    'jofemarNuukChannels'
> {
    jofemarNuukCollection$ = this.jofemarNuukCollectionService.jofemarNuukCollection$;
    selectedJofemarNuuk: JofemarNuukFragment;
    private selectedJofemarNuukId$ = new BehaviorSubject<string | undefined>(undefined);

    constructor(private readonly jofemarNuukCollectionService: JofemarNuukCollectionService) {
        super();
        super.configure({
            document: GetJofemarNuukChannelListDocument,
            getItems: data => data.jofemarNuukChannels,
            setVariables: (skip, take) => ({
                jofemarNuukId: this.selectedJofemarNuukId$.getValue() || '',
                options: {
                    skip,
                    take,
                },
            }),
            refreshListOnChanges: [this.selectedJofemarNuukId$],
        });
    }

    ngOnInit() {
        super.ngOnInit();

        this.jofemarNuukCollectionService.getSelectedJofemarNuukId().subscribe(id => {
            if (!id) {
                return;
            }

            this.jofemarNuukCollection$.subscribe(jofemarNuukCollection => {
                const found = jofemarNuukCollection?.find(d => d.id === id);
                if (found) {
                    this.selectJofemarNuuk(found);
                }
            });
        });
    }

    selectJofemarNuuk(jofemarNuuk: JofemarNuukFragment) {
        if (this.selectedJofemarNuukId$.getValue() === jofemarNuuk.id) {
            return;
        }
        this.jofemarNuukCollectionService.setSelectedJofemarNuukId(jofemarNuuk.id);
        this.selectedJofemarNuuk = jofemarNuuk;
        this.selectedJofemarNuukId$.next(jofemarNuuk.id);
    }
}
