import { NgModule } from '@angular/core';
import { registerDashboardWidget, setDashboardWidgetLayout } from '@vendure/admin-ui/core';
import { MetricsWidgetModule } from './metrics-widget.module';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl);

@NgModule({
    imports: [MetricsWidgetModule],
    declarations: [],
    providers: [
        registerDashboardWidget('metrics', {
            title: 'Statistieken',
            supportedWidths: [4, 6, 8, 12],
            loadComponent: () => import('./metrics-widget').then(m => m.MetricsWidgetComponent),
        }),
        setDashboardWidgetLayout([{ id: 'metrics', width: 12 }]),
    ],
})
export class MetricsWidgetSharedModule {}
