import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule, TypedBaseListComponent } from '@vendure/admin-ui/core';
import { GetJofemarVisionEsPlusModulesDocument } from '../../../generated-types';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'jofemar-vision-es-plus-modules',
    templateUrl: './jofemar-vision-es-plus-modules.component.html',
    styleUrls: ['./jofemar-vision-es-plus-modules.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class JofemarVisionEsPlusModulesComponent extends TypedBaseListComponent<
    typeof GetJofemarVisionEsPlusModulesDocument,
    'jofemarVisionEsPlusCollection'
> {
    itemsPerPage$ = new BehaviorSubject(50);
    readonly filters = this.createFilterCollection().addIdFilter().connectToRoute(this.route);

    readonly sorts = this.createSortCollection()
        .defaultSort('createdAt', 'DESC')
        .addSort({ name: 'createdAt' })
        .addSort({ name: 'updatedAt' })
        .addSort({ name: 'id' })
        .connectToRoute(this.route);

    constructor() {
        super();
        super.configure({
            document: GetJofemarVisionEsPlusModulesDocument,
            getItems: data => data.jofemarVisionEsPlusCollection,
            setVariables: (skip, take) => ({
                options: {
                    skip,
                    take,
                    filter: {
                        ...this.filters.createFilterInput(),
                    },
                    sort: this.sorts.createSortInput(),
                },
            }),
            refreshListOnChanges: [this.filters.valueChanges, this.sorts.valueChanges],
        });
    }
}
