<vdr-page-block>
    <vdr-action-bar>
        <vdr-ab-left>
            <div
                class="agromaat-btn-group agromaat-btn-outline-primary mode-select"
                *ngIf="selectedJofemarNuuk"
            >
                <div *ngFor="let option of modeOptions" class="radio agromaat-btn">
                    <input
                        type="radio"
                        name="mode"
                        [id]="option.id"
                        [title]="option.title"
                        [value]="option.value"
                        [checked]="mode === option.value"
                    />
                    <label [for]="option.id" (click)="setMode(option.value)">
                        <cds-icon
                            *ngIf="option.iconShape === 'arrow'"
                            [attr.direction]="option.iconDirection"
                            [attr.shape]="option.iconShape"
                        ></cds-icon>
                        <clr-icon
                            *ngIf="option.iconShape !== 'arrow'"
                            [attr.shape]="option.iconShape"
                        ></clr-icon>
                    </label>
                </div>
            </div>
        </vdr-ab-left>
        <vdr-ab-right>
            <div class="flex clr-row action-buttons-container items-center">
                <button
                    [hidden]="!('SuperAdmin' | hasPermission) || !selectedJofemarNuuk"
                    [disabled]="selectedJofemarNuuk?.isSyncing"
                    class="btn btn-secondary"
                    (click)="syncJofemarNuuk()"
                >
                    <span *ngIf="selectedJofemarNuuk?.isSyncing">
                        <clr-spinner clrInline />
                    </span>
                    {{ 'agromaat.dialog.start-sync-agrofreeze.button' | translate }}
                </button>

                <button
                    [hidden]="!('SuperAdmin' | hasPermission) || !selectedJofemarNuuk"
                    class="btn btn-warning"
                    (click)="resetFaults()"
                >
                    {{ 'agromaat.dialog.reset-faults-agrofreeze.button' | translate }}
                </button>
                <device-dropdown
                    [devices]="jofemarNuukCollection$ | async"
                    [selectedDevice]="selectedJofemarNuuk"
                    (deviceSelected)="selectJofemarNuuk($event)"
                ></device-dropdown>
            </div>
        </vdr-ab-right>
    </vdr-action-bar>
</vdr-page-block>

<vdr-page-detail-layout>
    <vdr-page-block>
        <clr-accordion
            class="accordion"
            [clrAccordionMultiPanel]="true"
            *ngIf="(loading$ | async) === false; else placeholder"
        >
            <ng-container *ngFor="let item of items$ | async; trackBy: trackByJofemarNuukTrayId">
                <clr-accordion-panel
                    [clrAccordionPanelOpen]="panels[item.id] || true"
                    (clrAccordionPanelOpenChange)="togglePanel(item.id)"
                >
                    <clr-accordion-title>Rij {{ item.sequence }}</clr-accordion-title>
                    <clr-accordion-content class="accordion-content">
                        <div class="accordion-grid">
                            <div
                                *ngFor="
                                    let jofemarNuukChannel of item.jofemarNuukChannels;
                                    trackBy: trackByJofemarNuukChannelId
                                "
                                class="accordion-grid-item"
                                [ngClass]="{ 'in-editmode': mode !== 'home' }"
                                (click)="mode !== 'home' ? update(jofemarNuukChannel) : null"
                            >
                                <span class="in-stock">{{ jofemarNuukChannel.inStock }}</span>
                                <div
                                    *ngIf="
                                        jofemarNuukChannel.productVariant as productVariant;
                                        else noProduct
                                    "
                                >
                                    <img
                                        *ngIf="
                                            productVariant.featuredAsset ||
                                                productVariant.product.featuredAsset as asset;
                                            else noImage
                                        "
                                        [src]="asset | assetPreview: 'thumb'"
                                        alt="{{ jofemarNuukChannel.channelAlias }} - {{
                                            jofemarNuukChannel.productVariant.name
                                        }}"
                                    />
                                    <span class="product-name">
                                        {{ jofemarNuukChannel.productVariant.name }}
                                    </span>
                                </div>
                                <ng-template #noProduct>
                                    <div class="fallback-product-div">
                                        <p>Nog geen product gekoppeld</p>
                                    </div>
                                </ng-template>
                                <ng-template #noImage>
                                    <div class="fallback-image-div">
                                        <p>
                                            {{ jofemarNuukChannel.productVariant.name }}
                                        </p>
                                    </div>
                                </ng-template>
                                <span class="channel-alias">
                                    {{ jofemarNuukChannel.channelAlias }}
                                    <div *ngIf="jofemarNuukChannel.updating" class="loading-indicator">
                                        <clr-spinner clrInline />
                                    </div>
                                </span>
                            </div>
                        </div>
                    </clr-accordion-content>
                </clr-accordion-panel>
            </ng-container>
        </clr-accordion>
        <ng-template #placeholder>
            <div class="loading">
                <clr-spinner></clr-spinner>
            </div>
        </ng-template>
    </vdr-page-block>
</vdr-page-detail-layout>
