import { DataService, ModalService, NotificationService, registerBulkAction } from '@vendure/admin-ui/core';
import {
    BulkUpdateJofemarVisionEsPlusChannelProductVariantDocument,
    JofemarVisionEsPlusChannel,
} from '../../../generated-types';
import { Injector } from '@angular/core';
import { JofemarVisionEsPlusLayoutComponent } from '../../../components/jofemar-vision-es-plus/jofemar-vision-es-plus-layout/jofemar-vision-es-plus-layout.component';
import { ProductVariantSelectDialogComponent } from '../../../components/support/product-variant-select-dialog/product-variant-select-dialog.component';
import { DeliverType } from '../../../ui-types';
import { Locations } from '../constants';

export const changeJofemarVisionEsPlusProductVariant = registerBulkAction({
    location: Locations.jofemarVisionEsPlusList,
    label: 'agromaat.bulk.change-product-variant',
    icon: 'edit',
    onClick: ({
        selection,
        injector,
        hostComponent,
    }: {
        selection: JofemarVisionEsPlusChannel[];
        injector: Injector;
        hostComponent: JofemarVisionEsPlusLayoutComponent;
    }) => {
        const ids = selection.map(({ id }) => id);
        const modalService = injector.get(ModalService);
        const dataService = injector.get(DataService);
        const notificationService = injector.get(NotificationService);
        modalService
            .fromComponent(ProductVariantSelectDialogComponent, {
                locals: {
                    cancellable: true,
                    deliverDevice: DeliverType.JOFEMAR_VISION_ES_PLUS,
                },
            })
            .subscribe(id => {
                if (!id) {
                    return;
                }

                return dataService
                    .mutate(BulkUpdateJofemarVisionEsPlusChannelProductVariantDocument, {
                        input: {
                            ids,
                            productVariantId: id[0],
                        },
                    })
                    .subscribe({
                        next: result => {
                            hostComponent.refresh();
                            if (
                                result.bulkUpdateJofemarVisionEsPlusChannelProductVariant.length !==
                                ids.length
                            ) {
                                notificationService.error('common.notify-update-error', {
                                    entity: 'AgroFlex kanaal',
                                });
                                return;
                            }
                            notificationService.success('common.notify-update-success', {
                                entity: 'AgroFlex kanaal',
                            });
                        },
                        error: () =>
                            notificationService.error('common.notify-update-error', {
                                entity: 'AgroFlex kanaal',
                            }),
                    });
            });
    },
});
