<vdr-data-table-2
    id="jofemar-nuuk-modules-list"
    [items]="items$ | async"
    [itemsPerPage]="itemsPerPage$ | async"
    [totalItems]="totalItems$ | async"
    [currentPage]="currentPage$ | async"
    (pageChange)="setPageNumber($event)"
    [filters]="filters"
    (itemsPerPageChange)="setItemsPerPage($event)"
>
    <vdr-bulk-action-menu
        locationId="jofemar-nuuk-modules-list"
        [hostComponent]="this"
        [selectionManager]="selectionManager"
    />
    <vdr-dt2-column [heading]="'common.id' | translate" [sort]="sorts.get('id')">
        <ng-template let-jofemarNuuk="item">{{ jofemarNuuk.id }}</ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.common.serialNumber' | translate">
        <ng-template let-jofemarNuuk="item">{{ jofemarNuuk.machineAddress.espSerialNumber }}</ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.common.connectivityStatus' | translate">
        <ng-template let-jofemarNuuk="item">
            {{ jofemarNuuk.connectivity.connectivityStatus | titlecase }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.common.lastConnectivityUpdate' | translate">
        <ng-template let-jofemarNuuk="item">
            {{ jofemarNuuk.connectivity.lastConnectivityUpdate | localeDate: 'short' }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.common.measurement' | translate">
        <ng-template let-jofemarNuuk="item">
            <div *ngIf="!jofemarNuuk?.chipTemperatureMeasure?.lastMeasuredChipTemperature">
                {{ 'agromaat.common.noMeasurementKnown' | translate }}
            </div>
            <vdr-dropdown *ngIf="jofemarNuuk?.chipTemperatureMeasure?.lastMeasuredChipTemperature">
                <button class="button-small btn-sm" vdrDropdownTrigger>
                    {{
                        jofemarNuuk?.chipTemperatureMeasure.lastMeasuredChipTemperatureTimestamp
                            | localeDate: 'short'
                    }}
                    <clr-icon shape="info-standard"></clr-icon>
                </button>
                <vdr-dropdown-menu>
                    <div class="result-detail">
                        <vdr-object-tree
                            [value]="jofemarNuuk?.chipTemperatureMeasure.lastMeasuredChipTemperature"
                        ></vdr-object-tree>
                    </div>
                </vdr-dropdown-menu>
            </vdr-dropdown>
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column
        [heading]="'common.created-at' | translate"
        id="created-at"
        [hiddenByDefault]="true"
        [sort]="sorts.get('createdAt')"
    >
        <ng-template let-jofemarNuuk="item">
            {{ jofemarNuuk.createdAt | localeDate: 'short' }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column
        [heading]="'common.updated-at' | translate"
        id="updated-at"
        [hiddenByDefault]="true"
        [sort]="sorts.get('updatedAt')"
    >
        <ng-template let-jofemarNuuk="item">
            {{ jofemarNuuk.updatedAt | localeDate: 'short' }}
        </ng-template>
    </vdr-dt2-column>
</vdr-data-table-2>
