<vdr-page-block>
    <vdr-action-bar>
        <vdr-ab-right>
            <div class="flex clr-row action-buttons-container items-center">
                <device-dropdown
                    [devices]="jofemarNuukCollection$ | async"
                    [selectedDevice]="selectedJofemarNuuk"
                    (deviceSelected)="selectJofemarNuuk($event)"
                ></device-dropdown>
            </div>
        </vdr-ab-right>
    </vdr-action-bar>
</vdr-page-block>

<vdr-data-table-2
    id="jofemar-nuuk-list"
    [items]="items$ | async"
    [itemsPerPage]="itemsPerPage$ | async"
    [totalItems]="totalItems$ | async"
    [currentPage]="currentPage$ | async"
    (pageChange)="setPageNumber($event)"
    (itemsPerPageChange)="setItemsPerPage($event)"
>
    <vdr-bulk-action-menu
        locationId="jofemar-nuuk-list"
        [hostComponent]="this"
        [selectionManager]="selectionManager"
    />
    <!-- Here we define all the available columns -->
    <vdr-dt2-column [heading]="'common.id' | translate" [hiddenByDefault]="true">
        <ng-template let-jofemarNuukChannel="item">{{ jofemarNuukChannel.id }}</ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.agrofreeze.channelAlias' | translate">
        <ng-template let-jofemarNuukChannel="item">{{ jofemarNuukChannel.channelAlias }}</ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'common.image' | translate" id="image">
        <ng-template let-jofemarNuukChannel="item">
            <div class="image-placeholder">
                <img
                    *ngIf="
                        jofemarNuukChannel.productVariant?.featuredAsset ||
                            jofemarNuukChannel.productVariant?.product.featuredAsset as asset;
                        else imagePlaceholder
                    "
                    [src]="asset | assetPreview: 'tiny'"
                />
                <ng-template #imagePlaceholder>
                    <div class="placeholder">
                        <clr-icon shape="image" size="48"></clr-icon>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'catalog.variant' | translate">
        <ng-template let-jofemarNuukChannel="item">
            {{ jofemarNuukChannel.productVariant?.name || 'Geen product gekoppeld' }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'catalog.stock-on-hand' | translate">
        <ng-template let-jofemarNuukChannel="item">
            {{ jofemarNuukChannel.inStock }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'common.created-at' | translate" id="created-at" [hiddenByDefault]="true">
        <ng-template let-jofemarNuukChannel="item">
            {{ jofemarNuukChannel.createdAt | localeDate: 'short' }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'common.updated-at' | translate" id="updated-at" [hiddenByDefault]="true">
        <ng-template let-jofemarNuukChannel="item">
            {{ jofemarNuukChannel.updatedAt | localeDate: 'short' }}
        </ng-template>
    </vdr-dt2-column>
</vdr-data-table-2>
