import { DataService, ModalService, NotificationService, registerBulkAction } from '@vendure/admin-ui/core';
import {
    BulkUpdateJofemarVisionEsPlusChannelStockDocument,
    JofemarVisionEsPlusChannel,
} from '../../../generated-types';
import { Injector } from '@angular/core';
import { JofemarVisionEsPlusLayoutComponent } from '../../../components/jofemar-vision-es-plus/jofemar-vision-es-plus-layout/jofemar-vision-es-plus-layout.component';
import { UpdateStockDialogComponent } from '../../../components/support/update-stock-dialog/update-stock-dialog.component';
import { Locations } from '../constants';

export const changeJofemarVisionEsPlusStock = registerBulkAction({
    location: Locations.jofemarVisionEsPlusList,
    label: 'agromaat.bulk.change-stock',
    icon: 'edit',
    onClick: ({
        selection,
        injector,
        hostComponent,
    }: {
        selection: JofemarVisionEsPlusChannel[];
        injector: Injector;
        hostComponent: JofemarVisionEsPlusLayoutComponent;
    }) => {
        const ids = selection.map(({ id }) => id);
        const modalService = injector.get(ModalService);
        const dataService = injector.get(DataService);
        const notificationService = injector.get(NotificationService);
        modalService
            .fromComponent(UpdateStockDialogComponent, {
                size: 'md',
                locals: {
                    stock: 0,
                },
            })
            .subscribe(inStock => {
                if (inStock === undefined) {
                    return;
                }

                return dataService
                    .mutate(BulkUpdateJofemarVisionEsPlusChannelStockDocument, {
                        input: {
                            ids,
                            inStock,
                        },
                    })
                    .subscribe({
                        next: result => {
                            hostComponent.refresh();
                            if (result.bulkUpdateJofemarVisionEsPlusChannelStock.length !== ids.length) {
                                notificationService.error('common.notify-update-error', {
                                    entity: 'AgroFlex kanaal',
                                });
                                return;
                            }
                            notificationService.success('common.notify-update-success', {
                                entity: 'AgroFlex kanaal',
                            });
                        },
                        error: () =>
                            notificationService.error('common.notify-update-error', {
                                entity: 'AgroFlex kanaal',
                            }),
                    });
            });
    },
});
