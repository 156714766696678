import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog, SharedModule } from '@vendure/admin-ui/core';

@Component({
    selector: 'update-post-dispense-channel-time-dialog',
    templateUrl: './update-post-dispense-channel-time-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class UpdatePostDispenseChannelTimeDialogComponent implements Dialog<number> {
    resolveWith: (result?: number) => void;
    postDispenseChannelTime: number = 5;
    itemNames: string;
    nMore: number;

    submit() {
        this.resolveWith(this.postDispenseChannelTime);
    }

    cancel() {
        this.resolveWith();
    }
}
