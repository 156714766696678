import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SharedModule } from '@vendure/admin-ui/core';

export interface Device {
    id: string;
    name: string;
    machineAddress: {
        espSerialNumber: string;
        port?: string;
    };
    isSyncing: boolean;
}

@Component({
    selector: 'device-dropdown',
    templateUrl: './device-dropdown.component.html',
    styleUrls: ['./device-dropdown.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class DeviceDropdownComponent implements OnChanges {
    @Input() devices: Device[] = [];
    @Input() selectedDevice: Device | null = null;
    @Output() deviceSelected = new EventEmitter<Device>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['devices'] && this.devices?.length > 0 && !this.selectedDevice) {
            this.selectDevice(this.devices[0]);
        }
    }

    selectDevice(device: Device) {
        this.deviceSelected.emit(device);
    }
}
