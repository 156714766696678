import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule, TypedBaseListComponent } from '@vendure/admin-ui/core';
import { GetJofemarNuukModulesDocument } from '../../../generated-types';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'jofemar-nuuk-modules-list',
    templateUrl: './jofemar-nuuk-modules.component.html',
    styleUrls: ['./jofemar-nuuk-modules.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class JofemarNuukModulesComponent extends TypedBaseListComponent<
    typeof GetJofemarNuukModulesDocument,
    'jofemarNuukCollection'
> {
    itemsPerPage$ = new BehaviorSubject(50);
    readonly filters = this.createFilterCollection().addIdFilter().connectToRoute(this.route);

    readonly sorts = this.createSortCollection()
        .defaultSort('createdAt', 'DESC')
        .addSort({ name: 'createdAt' })
        .addSort({ name: 'updatedAt' })
        .addSort({ name: 'id' })
        .connectToRoute(this.route);

    constructor() {
        super();
        super.configure({
            document: GetJofemarNuukModulesDocument,
            getItems: data => data.jofemarNuukCollection,
            setVariables: (skip, take) => ({
                options: {
                    skip,
                    take,
                    filter: {
                        ...this.filters.createFilterInput(),
                    },
                    sort: this.sorts.createSortInput(),
                },
            }),
            refreshListOnChanges: [this.filters.valueChanges, this.sorts.valueChanges],
        });
    }
}
