import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataService, NotificationService, SharedModule } from '@vendure/admin-ui/core';
import { FulfillProductCellDocument, ProductVariant } from '../../../generated-types';
import { FilteredRelationProductVariantInputComponent } from '../../support/filtered-vdr-relation-product-variant-input/filtered-relation-product-variant-input.component';

@Component({
    selector: 'product-cell-fulfill',
    templateUrl: './product-cell-fulfill.component.html',
    styleUrls: ['./product-cell-fulfill.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [SharedModule, FilteredRelationProductVariantInputComponent],
})
export class ProductCellFulfillComponent {
    htmlFormInputConfigArgsDef = {
        name: 'productVariant',
        type: 'relation',
        list: false,
        required: true,
        entity: 'ProductVariant',
    };

    detailForm = this.formBuilder.group({
        productVariant: [null as ProductVariant | null, Validators.required],
        quantity: [1, Validators.required],
    });
    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly notificationService: NotificationService,
    ) {}

    fulfillItems() {
        const productVariant = this.detailForm.get('productVariant')?.value;
        const input = {
            productVariantId: `${productVariant?.id}`,
            quantity: Number(this.detailForm.get('quantity')?.value),
        };
        this.dataService.mutate(FulfillProductCellDocument, { input }).subscribe({
            next: () => {
                this.notificationService.success('agromaat.settings.fulfill-product-cell-success', {
                    entity: 'Productvak',
                });
            },
            error: data => {
                if (data.message.includes('agromaat.error.invalid-quantity')) {
                    return;
                }
                this.notificationService.error('common.notify-create-error');
            },
        });
    }
}
