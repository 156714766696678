import { JofemarNuuk, MeasureJofemarNuukDocument } from '../../../generated-types';
import { BulkActionClickContext, DataService, NotificationService } from '@vendure/admin-ui/core';
import { lastValueFrom } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const measureJofemarNuukModule = async ({
    selection,
    injector,
}: BulkActionClickContext<JofemarNuuk, unknown>) => {
    const ids = selection.map(({ id }) => id);
    const dataService = injector.get(DataService);
    const notificationService = injector.get(NotificationService);
    for (const id of ids) {
        try {
            const result = await lastValueFrom(
                dataService.query(MeasureJofemarNuukDocument, {
                    id,
                }).single$,
            );

            notificationService.success(_('agromaat.bulk.measure-nuuk-success'), {
                id,
                chipTemperature: result.measureJofemarNuuk?.lastMeasuredChipTemperature || 0,
            });
        } catch {
            notificationService.error(_('agromaat.bulk.measure-nuuk-failed'));
        }
    }
};
