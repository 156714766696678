<ng-template vdrDialogTitle>{{
    'agromaat.bulk.change-product-variant-post-dispense-channel-time' | translate
}}</ng-template>
<div *ngIf="0 < nMore">
    {{
        'common.list-items-and-n-more'
            | translate
                : {
                      items: itemNames,
                      nMore: nMore,
                  }
    }}
</div>
<div *ngIf="nMore === 0">
    {{ itemNames }}
</div>
<hr class="my-1" style="opacity: 0.4" />
<div>
    <dfn>{{ 'agromaat.bulk.post-dispense-channel-time-message' | translate }}</dfn>
</div>
<div class="form-grid my-2">
    <vdr-form-field
        for="postDispenseChannelTime"
        label="{{ 'agromaat.bulk.post-dispense-channel-time' | translate }}"
    >
        <input
            name="postDispenseChannelTime"
            [(ngModel)]="postDispenseChannelTime"
            type="number"
            min="0"
            step="1"
            max="100"
        />
    </vdr-form-field>
</div>

<ng-template vdrDialogButtons>
    <button type="button" class="btn" (click)="cancel()">
        {{ 'common.cancel' | translate }}
    </button>
    <button type="submit" (click)="submit()" class="btn btn-primary">
        {{ 'common.confirm' | translate }}
    </button>
</ng-template>
