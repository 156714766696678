import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog, SharedModule } from '@vendure/admin-ui/core';

@Component({
    selector: 'update-dispensing-speed-dialog',
    templateUrl: './update-dispensing-speed-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class UpdateDispensingSpeedDialogComponent implements Dialog<number> {
    resolveWith: (result?: number) => void;
    dispensingSpeed: number = 5;
    itemNames: string;
    nMore: number;

    submit() {
        this.resolveWith(this.dispensingSpeed);
    }

    cancel() {
        this.resolveWith();
    }
}
