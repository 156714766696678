import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog, SharedModule } from '@vendure/admin-ui/core';
import { ProductCellState } from '../../../generated-types';

@Component({
    selector: 'vdr-product-cell-state-select-dialog',
    templateUrl: './product-cell-state-select-dialog.component.html',
    styleUrls: ['./product-cell-state-select-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class ProductCellStateSelectDialogComponent implements Dialog<ProductCellState> {
    resolveWith: (result?: ProductCellState) => void;
    protected readonly availableStates: ProductCellState[] = Object.values(ProductCellState);
    cancellable: boolean;
    selectedState: ProductCellState | '' = '';

    select() {
        if (this.selectedState !== '') {
            this.resolveWith(this.selectedState);
        }
    }

    cancel() {
        this.resolveWith();
    }
}
