import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule, TypedBaseListComponent } from '@vendure/admin-ui/core';
import { DeviceDropdownComponent } from '../../support/device-dropdown/device-dropdown.component';
import { JofemarVisionEsPlusCollectionService } from '../../../services/jofemar-vision-es-plus/jofemar-vision-es-plus-collection.service';
import {
    GetJofemarVisionEsPlusChannelListDocument,
    JofemarVisionEsPlusFragment,
} from '../../../generated-types';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'jofemar-vision-es-plus-list',
    templateUrl: './jofemar-vision-es-plus-list.component.html',
    styleUrls: ['./jofemar-vision-es-plus-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule, DeviceDropdownComponent],
})
export class JofemarVisionEsPlusListComponent extends TypedBaseListComponent<
    typeof GetJofemarVisionEsPlusChannelListDocument,
    'jofemarVisionEsPlusChannels'
> {
    jofemarVisionEsPlusCollection$ = this.jofemarVisionEsPlusCollectionService.jofemarVisionEsPlusCollection$;
    selectedJofemarVisionEsPlus: JofemarVisionEsPlusFragment;
    private selectedJofemarVisionEsPlusId$ = new BehaviorSubject<string | undefined>(undefined);

    constructor(private readonly jofemarVisionEsPlusCollectionService: JofemarVisionEsPlusCollectionService) {
        super();
        super.configure({
            document: GetJofemarVisionEsPlusChannelListDocument,
            getItems: data => data.jofemarVisionEsPlusChannels,
            setVariables: (skip, take) => ({
                jofemarVisionEsPlusId: this.selectedJofemarVisionEsPlusId$.getValue() || '',
                options: {
                    skip,
                    take,
                },
            }),
            refreshListOnChanges: [this.selectedJofemarVisionEsPlusId$],
        });
    }

    ngOnInit() {
        super.ngOnInit();

        this.jofemarVisionEsPlusCollectionService.getSelectedJofemarVisionEsPlusId().subscribe(id => {
            if (!id) {
                return;
            }

            this.jofemarVisionEsPlusCollection$.subscribe(jofemarVisionEsPlusCollection => {
                const found = jofemarVisionEsPlusCollection?.find(d => d.id === id);
                if (found) {
                    this.selectJofemarVisionEsPlus(found);
                }
            });
        });
    }

    selectJofemarVisionEsPlus(jofemarVisionEsPlus: JofemarVisionEsPlusFragment) {
        if (this.selectedJofemarVisionEsPlusId$.getValue() === jofemarVisionEsPlus.id) {
            return;
        }
        this.jofemarVisionEsPlusCollectionService.setSelectedJofemarVisionEsPlusId(jofemarVisionEsPlus.id);
        this.selectedJofemarVisionEsPlus = jofemarVisionEsPlus;
        this.selectedJofemarVisionEsPlusId$.next(jofemarVisionEsPlus.id);
    }
}
