import { DataService, ModalService, NotificationService, registerBulkAction } from '@vendure/admin-ui/core';
import {
    JofemarVisionEsPlusChannel,
    UpdateProductVariantInput,
    UpdateProductVariantsDocument,
} from '../../../generated-types';
import { Injector } from '@angular/core';
import { JofemarVisionEsPlusLayoutComponent } from '../../../components/jofemar-vision-es-plus/jofemar-vision-es-plus-layout/jofemar-vision-es-plus-layout.component';
import { Locations } from '../constants';
import { UpdateIsFragileDialogComponent } from '../../../components/support/update-is-fragile-dialog/update-is-fragile-dialog.component';

export const changeJofemarVisionEsPlusProductVariantIsFragile = registerBulkAction({
    location: Locations.jofemarVisionEsPlusList,
    label: 'agromaat.bulk.change-product-variant-is-fragile',
    icon: 'tag',
    onClick: ({
        selection,
        injector,
        hostComponent,
    }: {
        selection: JofemarVisionEsPlusChannel[];
        injector: Injector;
        hostComponent: JofemarVisionEsPlusLayoutComponent;
    }) => {
        const ids: string[] = Array.from(
            new Set(selection.map(({ productVariant }) => productVariant?.id).filter(Boolean)),
        ) as string[];
        const defaultIsFragile = selection.every(c => c?.productVariant?.customFields?.isFragile);
        const allNames = Array.from(new Set(selection.map(c => c.productVariant?.name)));
        const itemNames = allNames.slice(0, 5).join(', ');
        const nMore = allNames.length > 5 ? allNames.length - 5 : 0;
        const modalService = injector.get(ModalService);
        const dataService = injector.get(DataService);
        const notificationService = injector.get(NotificationService);
        modalService
            .fromComponent(UpdateIsFragileDialogComponent, {
                size: 'md',
                locals: {
                    isFragile: defaultIsFragile,
                    itemNames,
                    nMore,
                },
            })
            .subscribe(isFragile => {
                if (isFragile === undefined) {
                    return;
                }

                const input: UpdateProductVariantInput[] = ids.map(id => {
                    return {
                        id,
                        customFields: {
                            isFragile,
                        },
                    };
                });

                return dataService.mutate(UpdateProductVariantsDocument, { input }).subscribe({
                    next: result => {
                        hostComponent.refresh();
                        if (result.updateProductVariants.length !== ids.length) {
                            notificationService.error('common.notify-update-error', {
                                entity: 'ProductVariant',
                            });
                            return;
                        }
                        notificationService.success('common.notify-update-success', {
                            entity: 'ProductVariant',
                        });
                    },
                    error: () =>
                        notificationService.error('common.notify-update-error', {
                            entity: 'ProductVariant',
                        }),
                });
            });
    },
});
