import { DataService, ModalService, NotificationService, registerBulkAction } from '@vendure/admin-ui/core';
import { BulkUpdateProductCellProductVariantDocument, ProductCell } from '../../../generated-types';
import { Injector } from '@angular/core';
import { ProductCellsListComponent } from '../../../components/product-cell/product-cells-list/product-cells-list.component';
import { ProductVariantSelectDialogComponent } from '../../../components/support/product-variant-select-dialog/product-variant-select-dialog.component';
import { DeliverType } from '../../../ui-types';
import { Locations } from '../constants';

export const changeProductCellProductVariant = registerBulkAction({
    location: Locations.productCellsList,
    label: 'agromaat.bulk.change-product-variant',
    icon: 'edit',
    isVisible: ({ selection }: { selection: ProductCell[] }) =>
        new Set(selection.map(({ type, productModule }) => `${type}-${productModule.vending.cooling}`))
            .size === 1,
    onClick: ({
        selection,
        injector,
        hostComponent,
    }: {
        selection: ProductCell[];
        injector: Injector;
        hostComponent: ProductCellsListComponent;
    }) => {
        const ids = selection.map(({ id }) => id);
        const modalService = injector.get(ModalService);
        const dataService = injector.get(DataService);
        const notificationService = injector.get(NotificationService);
        modalService
            .fromComponent(ProductVariantSelectDialogComponent, {
                locals: {
                    cancellable: true,
                    // @TODO filter based on supported product cell types
                    deliverDevice: DeliverType.VENDING,
                    productCellType: selection.map(({ type }) => type)[0],
                    cooling: selection.map(({ productModule }) => productModule.vending.cooling)[0],
                },
            })
            .subscribe(id => {
                if (!id) {
                    return;
                }

                return dataService
                    .mutate(BulkUpdateProductCellProductVariantDocument, {
                        input: {
                            ids,
                            productVariantId: id[0],
                        },
                    })
                    .subscribe({
                        next: result => {
                            hostComponent.refresh();
                            if (result.bulkUpdateProductCellProductVariant.length !== ids.length) {
                                notificationService.error('common.notify-update-error', {
                                    entity: 'Productvak',
                                });
                                return;
                            }
                            notificationService.success('common.notify-update-success', {
                                entity: 'Productvak',
                            });
                        },
                        error: () =>
                            notificationService.error('common.notify-update-error', {
                                entity: 'Productvak',
                            }),
                    });
            });
    },
});
