import { registerPageTab } from '@vendure/admin-ui/core';
import { ProductCellsLayoutComponent } from '../components/product-cell/product-cells-layout/product-cells-layout.component';
import { ProductCellsListComponent } from '../components/product-cell/product-cells-list/product-cells-list.component';
import { ProductCellFulfillComponent } from '../components/product-cell/product-cell-fulfill/product-cell-fulfill.component';
import { VendingModulesComponent } from '../components/product-cell/vending-modules/vending-modules.component';
import { JofemarVisionEsPlusModulesComponent } from '../components/jofemar-vision-es-plus/jofemar-vision-es-plus-modules/jofemar-vision-es-plus-modules.component';
import { JofemarNuukModulesComponent } from '../components/jofemar-nuuk/jofemar-nuuk-modules/jofemar-nuuk-modules.component';
import { MilktapModulesComponent } from '../components/milktap/milktap-modules/milktap-modules.component';
import { JofemarVisionEsPlusLayoutComponent } from '../components/jofemar-vision-es-plus/jofemar-vision-es-plus-layout/jofemar-vision-es-plus-layout.component';
import { JofemarVisionEsPlusListComponent } from '../components/jofemar-vision-es-plus/jofemar-vision-es-plus-list/jofemar-vision-es-plus-list.component';
import { JofemarNuukLayoutComponent } from '../components/jofemar-nuuk/jofemar-nuuk-layout/jofemar-nuuk-layout.component';
import { JofemarNuukListComponent } from '../components/jofemar-nuuk/jofemar-nuuk-list/jofemar-nuuk-list.component';
import { JofemarVisionEsPlusSettingsComponent } from '../components/jofemar-vision-es-plus/jofemar-vision-es-plus-settings/jofemar-vision-es-plus-settings.component';
import { JofemarNuukSettingsComponent } from '../components/jofemar-nuuk/jofemar-nuuk-settings/jofemar-nuuk-settings.component';

export const tabs = [
    registerPageTab({
        location: 'agrobox' as any,
        tab: 'agromaat.common.agrobox-layout',
        route: '/extensions/agromaat-stock/agrobox-layout',
        component: ProductCellsLayoutComponent,
    }),
    registerPageTab({
        location: 'agrobox' as any,
        tab: 'agromaat.common.agrobox-list',
        route: '/extensions/agromaat-stock/agrobox-list',
        component: ProductCellsListComponent,
    }),
    registerPageTab({
        location: 'agrobox' as any,
        tab: 'agromaat.common.agrobox-refill',
        route: '/extensions/agromaat-stock/agrobox-refill',
        component: ProductCellFulfillComponent,
    }),
    registerPageTab({
        location: 'product-modules' as any,
        tab: 'AgroBox',
        route: '/extensions/agromaat-stock/product-modules/agrobox',
        component: VendingModulesComponent,
    }),
    registerPageTab({
        location: 'product-modules' as any,
        tab: 'AgroFlex',
        route: '/extensions/agromaat-stock/product-modules/agroflex',
        component: JofemarVisionEsPlusModulesComponent,
    }),
    registerPageTab({
        location: 'product-modules' as any,
        tab: 'AgroFreeze',
        route: '/extensions/agromaat-stock/product-modules/agrofreeze',
        component: JofemarNuukModulesComponent,
    }),
    registerPageTab({
        location: 'product-modules' as any,
        tab: 'AgroTap',
        route: '/extensions/agromaat-stock/product-modules/agrotap',
        component: MilktapModulesComponent,
    }),
    registerPageTab({
        location: 'agroflex' as any,
        tab: 'Overzicht',
        route: '/extensions/agromaat-stock/agroflex',
        component: JofemarVisionEsPlusLayoutComponent,
    }),
    registerPageTab({
        location: 'agroflex' as any,
        tab: 'Lijst',
        route: '/extensions/agromaat-stock/agroflex/list',
        component: JofemarVisionEsPlusListComponent,
    }),
    registerPageTab({
        location: 'agroflex' as any,
        tab: 'Instellingen',
        route: '/extensions/agromaat-stock/agroflex/settings',
        component: JofemarVisionEsPlusSettingsComponent,
    }),
    registerPageTab({
        location: 'agrofreeze' as any,
        tab: 'Overzicht',
        route: '/extensions/agromaat-stock/agrofreeze',
        component: JofemarNuukLayoutComponent,
    }),
    registerPageTab({
        location: 'agrofreeze' as any,
        tab: 'Lijst',
        route: '/extensions/agromaat-stock/agrofreeze/list',
        component: JofemarNuukListComponent,
    }),
    registerPageTab({
        location: 'agrofreeze' as any,
        tab: 'Instellingen',
        route: '/extensions/agromaat-stock/agrofreeze/settings',
        component: JofemarNuukSettingsComponent,
    }),
];
