<ng-template vdrDialogTitle>{{
    'agromaat.bulk.change-product-variant-adjusting-position-of-elevator' | translate
}}</ng-template>
<div *ngIf="0 < nMore">
    {{
        'common.list-items-and-n-more'
            | translate
                : {
                      items: itemNames,
                      nMore: nMore,
                  }
    }}
</div>
<div *ngIf="nMore === 0">
    {{ itemNames }}
</div>
<hr class="my-1" style="opacity: 0.4" />
<div>
    <dfn>{{ 'agromaat.bulk.adjusting-position-of-elevator-message' | translate }}</dfn>
</div>
<div class="form-grid my-2">
    <vdr-form-field
        for="adjustingPositionOfElevator"
        label="{{ 'agromaat.bulk.adjusting-position-of-elevator' | translate }}"
    >
        <select clrSelect name="adjustingPositionOfElevator" [(ngModel)]="values.adjustingPositionOfElevator">
            <option value="UPPER">
                {{ 'agromaat.bulk.adjusting-position-of-elevator-upper' | translate }}
            </option>
            <option value="LOWER">
                {{ 'agromaat.bulk.adjusting-position-of-elevator-lower' | translate }}
            </option>
        </select>
    </vdr-form-field>
    <vdr-form-field
        for="adjustingTimePositionOfElevator"
        label="{{ 'agromaat.bulk.adjusting-time-position-of-elevator' | translate }}"
    >
        <input
            name="adjustingTimePositionOfElevator"
            [(ngModel)]="values.adjustingTimePositionOfElevator"
            type="number"
            min="0"
            step="1"
        />
    </vdr-form-field>
</div>

<ng-template vdrDialogButtons>
    <button type="button" class="btn" (click)="cancel()">
        {{ 'common.cancel' | translate }}
    </button>
    <button type="submit" (click)="submit()" class="btn btn-primary">
        {{ 'common.confirm' | translate }}
    </button>
</ng-template>
