import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { DataService, NotificationService, SharedModule } from '@vendure/admin-ui/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
    GetJofemarVisionEsPlusDocument,
    GetJofemarVisionEsPlusQuery,
    UpdateJofemarVisionEsPlusDocument,
} from '../../../../generated-types';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Component({
    selector: 'jofemar-vision-es-plus-detail-form-setting',
    templateUrl: './jofemar-vision-es-plus-detail-form-setting.component.html',
    styleUrls: ['./jofemar-vision-es-plus-detail-form-setting.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class JofemarVisionEsPlusDetailFormSettingComponent implements OnInit {
    @Input() id!: string;
    entity$ = new BehaviorSubject<GetJofemarVisionEsPlusQuery['jofemarVisionEsPlus'] | null>(null);
    detailForm = this.formBuilder.group({
        name: ['', Validators.required],
        workingTemperature: [4, Validators.required],
        isLightOn: [true, Validators.required],
    });
    saveInProgress: boolean = false;

    constructor(
        private readonly formBuilder: NonNullableFormBuilder,
        private readonly dataService: DataService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly notificationService: NotificationService,
    ) {}

    ngOnInit(): void {
        this.dataService
            .query(GetJofemarVisionEsPlusDocument, {
                id: this.id,
            })
            .single$.subscribe(data => {
                if (!data.jofemarVisionEsPlus) {
                    return;
                }
                this.entity$.next(data.jofemarVisionEsPlus);
                this.detailForm.patchValue({
                    name: data.jofemarVisionEsPlus.name,
                    workingTemperature: data.jofemarVisionEsPlus.workingTemperature,
                    isLightOn: data.jofemarVisionEsPlus.isLightOn,
                });
            });
    }

    async save() {
        try {
            if (this.detailForm.dirty && !this.saveInProgress) {
                this.saveInProgress = true;
                const formValue = this.detailForm.value;
                const result$ = this.dataService.mutate(UpdateJofemarVisionEsPlusDocument, {
                    input: {
                        id: this.id,
                        ...formValue,
                    },
                });
                const result = await firstValueFrom(result$);
                this.entity$.next(result.updateJofemarVisionEsPlus);
                this.detailForm.markAsPristine();
                this.changeDetector.markForCheck();
                this.notificationService.success('common.notify-update-success', {
                    entity: 'AgroFlex',
                });
                this.saveInProgress = false;
                this.changeDetector.markForCheck();
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {
            this.notificationService.error('common.notify-update-error', {
                entity: 'AgroFlex',
            });
        }
    }
}
