import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog, SharedModule } from '@vendure/admin-ui/core';

@Component({
    selector: 'update-is-fragile-dialog',
    templateUrl: './update-is-fragile-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class UpdateIsFragileDialogComponent implements Dialog<boolean> {
    resolveWith: (result?: boolean) => void;
    isFragile: boolean;
    itemNames: string;
    nMore: number;

    submit() {
        this.resolveWith(this.isFragile);
    }

    cancel() {
        this.resolveWith();
    }
}
