import { registerFormInputComponent, setDashboardWidgetLayout } from '@vendure/admin-ui/core';
import { DeliverDeviceFormInputComponent } from './components/support/deliver-device-form-input/deliver-device-form-input.component';
import { GqlSubscriptionService } from './providers/gql-subscription-service';

import { tabs } from './providers/tabs';
import { navMenuSections } from './providers/nav-menu-sections';
import { bulkActions } from './providers/bulk-actions';
import { JofemarVisionEsPlusCollectionService } from './services/jofemar-vision-es-plus/jofemar-vision-es-plus-collection.service';
import { JofemarVisionEsPlusTrayService } from './services/jofemar-vision-es-plus/jofemar-vision-es-plus-tray.service';
import { JofemarNuukCollectionService } from './services/jofemar-nuuk/jofemar-nuuk-collection.service';
import { JofemarNuukTrayService } from './services/jofemar-nuuk/jofemar-nuuk-tray.service';

export default [
    JofemarNuukCollectionService,
    JofemarNuukTrayService,
    JofemarVisionEsPlusCollectionService,
    JofemarVisionEsPlusTrayService,
    GqlSubscriptionService,
    registerFormInputComponent('deliver-device-form-input', DeliverDeviceFormInputComponent),
    setDashboardWidgetLayout([]),
    ...bulkActions,
    ...navMenuSections,
    ...tabs,
];
