import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, interval, merge, Observable, of, timer } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { DataService, ModalService, NotificationService, PermissionsService } from '@vendure/admin-ui/core';
import {
    GetJofemarNuukCollectionDocument,
    JofemarNuukFragment,
    ResetFaultsJofemarNuukDocument,
    SyncJofemarNuukDocument,
} from '../../generated-types';

@Injectable()
export class JofemarNuukCollectionService {
    private refreshCollection$ = new BehaviorSubject<void>(undefined);
    private selectedJofemarNuukId$ = new BehaviorSubject<string | undefined>(undefined);
    jofemarNuukCollection$: Observable<JofemarNuukFragment[]>;

    constructor(
        private readonly dataService: DataService,
        private readonly permissionsService: PermissionsService,
        private readonly modalService: ModalService,
        private readonly notificationService: NotificationService,
    ) {
        this.jofemarNuukCollection$ = this.refreshCollection$.pipe(switchMap(() => this.pollForCollection()));
    }

    private pollForCollection(): Observable<JofemarNuukFragment[]> {
        return merge(timer(0), interval(5000)).pipe(
            switchMap(() =>
                this.permissionsService.userHasPermissions(['ManageJofemarNuukStock'])
                    ? this.fetchJofemarNuukCollection()
                    : of([]),
            ),
            takeWhile(
                collection =>
                    collection !== null &&
                    collection.some(el => this.selectedJofemarNuukId$.getValue() === el.id && el.isSyncing),
                true,
            ),
        );
    }

    private fetchJofemarNuukCollection(): Observable<JofemarNuukFragment[]> {
        return this.dataService
            .query(GetJofemarNuukCollectionDocument)
            .refetchOnChannelChange()
            .mapSingle(data => data.jofemarNuukCollection.items);
    }

    refresh() {
        this.refreshCollection$.next();
    }

    setSelectedJofemarNuukId(id: string) {
        this.selectedJofemarNuukId$.next(id);
    }

    getSelectedJofemarNuukId(): Observable<string | undefined> {
        return this.selectedJofemarNuukId$.asObservable();
    }

    resetFaults(): void {
        this.executeAction(
            'agromaat.dialog.reset-faults-agrofreeze.title',
            'agromaat.dialog.reset-faults-agrofreeze.body',
            ResetFaultsJofemarNuukDocument,
            'agromaat.dialog.reset-faults-agrofreeze.success',
            'agromaat.dialog.reset-faults-agrofreeze.failed',
        );
    }

    syncJofemarNuuk(): void {
        this.executeAction(
            'agromaat.dialog.start-sync-agrofreeze.title',
            'agromaat.dialog.start-sync-agrofreeze.body',
            SyncJofemarNuukDocument,
            'agromaat.dialog.start-sync-agrofreeze.success',
            'agromaat.dialog.start-sync-agrofreeze.failed',
        );
    }

    private executeAction(
        title: string,
        body: string,
        mutationDocument: typeof ResetFaultsJofemarNuukDocument | typeof SyncJofemarNuukDocument,
        successMessage: string,
        errorMessage: string,
    ) {
        const jofemarNuukId = this.selectedJofemarNuukId$.value;
        if (!jofemarNuukId) {
            return;
        }

        this.confirmAction(title, body)
            .pipe(
                switchMap(response =>
                    response ? this.performMutation(mutationDocument, jofemarNuukId) : EMPTY,
                ),
            )
            .subscribe(result => {
                if (result.action.success) {
                    this.notificationService.success(successMessage);
                    this.refresh();
                    return;
                }
                this.notificationService.error(errorMessage);
            });
    }

    private confirmAction(title: string, body: string) {
        return combineLatest([
            this.modalService.dialog({
                title,
                buttons: [
                    { label: 'common.cancel', type: 'secondary' },
                    { label: 'common.confirm', type: 'primary', returnValue: true },
                ],
                body,
            }),
        ]).pipe(switchMap(([response]) => of(response)));
    }

    private performMutation(
        mutationDocument: typeof ResetFaultsJofemarNuukDocument | typeof SyncJofemarNuukDocument,
        jofemarNuukId: string,
    ) {
        return this.dataService.mutate(mutationDocument, { input: { jofemarNuukId } });
    }
}
