import { changeJofemarNuukProductVariant } from './change-jofemar-nuuk-product-variant';
import { changeJofemarVisionEsPlusProductVariant } from './change-jofemar-vision-es-plus-product-variant';
import { changeJofemarVisionEsPlusProductVariantDispensingSpeed } from './change-jofemar-vision-es-plus-product-variant-dispensing-speed';
import { changeJofemarVisionEsPlusProductVariantIsFragile } from './change-jofemar-vision-es-plus-product-variant-is-fragile';
import { changeProductCellProductVariant } from './change-product-cell-product-variant';
import { changeJofemarVisionEsPlusProductVariantPostDispenseChannelTime } from './change-jofemar-vision-es-plus-product-variant-post-dispense-channel-time';
import { changeJofemarVisionEsPlusProductVariantAdjustingPositionOfElevator } from './change-jofemar-vision-es-plus-product-variant-adjusting-position-of-elevator';

export const changeProductVariantActions = [
    changeJofemarNuukProductVariant,
    changeJofemarVisionEsPlusProductVariant,
    changeJofemarVisionEsPlusProductVariantIsFragile,
    changeJofemarVisionEsPlusProductVariantAdjustingPositionOfElevator,
    changeJofemarVisionEsPlusProductVariantDispensingSpeed,
    changeJofemarVisionEsPlusProductVariantPostDispenseChannelTime,
    changeProductCellProductVariant,
];
