import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog, SharedModule } from '@vendure/admin-ui/core';

type Values = {
    adjustingPositionOfElevator: 'UPPER' | 'LOWER';
    adjustingTimePositionOfElevator: number;
};

@Component({
    selector: 'update-adjusting-position-of-elevator-dialog',
    templateUrl: './update-adjusting-position-of-elevator-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class UpdateAdjustingPositionOfElevatorDialogComponent implements Dialog<Values> {
    resolveWith: (result?: Values) => void;
    values: Values;
    itemNames: string;
    nMore: number;

    submit() {
        this.resolveWith(this.values);
    }

    cancel() {
        this.resolveWith();
    }
}
