<vdr-page-detail-layout>
    <vdr-page-block>
        <div class="scrollable-container" cdkScrollable>
            <div *ngIf="products$ | async as products; else noProducts">
                <div
                    cdkDropList
                    class="product-list"
                    [cdkDropListData]="products"
                    (cdkDropListDropped)="drop($event)"
                    cdkDragLockAxis="y"
                >
                    <div
                        *ngFor="let product of products"
                        class="product-item"
                        cdkDrag
                        (cdkDragMoved)="onDragMoved($event)"
                        (cdkDragStarted)="onDragStarted()"
                        (cdkDragEnded)="onDragEnded()"
                        [cdkDragData]="product"
                        cdkDragBoundary=".scrollable-container"
                    >
                        <div class="drag-handle" cdkDragHandle>
                            <clr-icon shape="drag-handle"></clr-icon>
                        </div>
                        <div class="image-container">
                            <div class="image-placeholder">
                                <img
                                    *ngIf="product.featuredAsset as asset; else imagePlaceholder"
                                    [src]="asset | assetPreview: 'tiny'"
                                />
                                <ng-template #imagePlaceholder>
                                    <div class="placeholder">
                                        <clr-icon shape="image" size="48"></clr-icon>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        {{ product.name }}
                    </div>
                </div>
            </div>
        </div>
        <ng-template #noProducts>
            <p>No products available.</p>
        </ng-template>
    </vdr-page-block>
</vdr-page-detail-layout>
