import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { DataService, NotificationService, SharedModule } from '@vendure/admin-ui/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
    GetJofemarNuukDocument,
    GetJofemarNuukQuery,
    UpdateJofemarNuukDocument,
} from '../../../../generated-types';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Component({
    selector: 'jofemar-nuuk-detail-form-setting',
    templateUrl: './jofemar-nuuk-detail-form-setting.component.html',
    styleUrls: ['./jofemar-nuuk-detail-form-setting.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class JofemarNuukDetailFormSettingComponent implements OnInit {
    @Input() id!: string;
    entity$ = new BehaviorSubject<GetJofemarNuukQuery['jofemarNuuk'] | null>(null);
    detailForm = this.formBuilder.group({
        name: ['', Validators.required],
        workingTemperature: [-18, Validators.required],
    });
    saveInProgress: boolean = false;

    constructor(
        private readonly formBuilder: NonNullableFormBuilder,
        private readonly dataService: DataService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly notificationService: NotificationService,
    ) {}

    ngOnInit(): void {
        this.dataService
            .query(GetJofemarNuukDocument, {
                id: this.id,
            })
            .single$.subscribe(data => {
                if (!data.jofemarNuuk) {
                    return;
                }
                this.entity$.next(data.jofemarNuuk);
                this.detailForm.patchValue({
                    name: data.jofemarNuuk.name,
                    workingTemperature: data.jofemarNuuk.workingTemperature,
                });
            });
    }

    async save() {
        try {
            if (this.detailForm.dirty && !this.saveInProgress) {
                this.saveInProgress = true;
                const formValue = this.detailForm.value;
                const result$ = this.dataService.mutate(UpdateJofemarNuukDocument, {
                    input: {
                        id: this.id,
                        ...formValue,
                    },
                });
                const result = await firstValueFrom(result$);
                this.entity$.next(result.updateJofemarNuuk);
                this.detailForm.markAsPristine();
                this.changeDetector.markForCheck();
                this.notificationService.success('common.notify-update-success', {
                    entity: 'AgroFlex',
                });
                this.saveInProgress = false;
                this.changeDetector.markForCheck();
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {
            this.notificationService.error('common.notify-update-error', {
                entity: 'AgroFlex',
            });
        }
    }
}
