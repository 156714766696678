<vdr-page-block>
    <vdr-card [title]="(entity$ | async)?.name" class="mb-3">
        <form [formGroup]="detailForm">
            <div class="form-grid">
                <vdr-form-field
                    label="{{ 'agromaat.agroflex.name' | translate }}"
                    for="name"
                    class="form-grid-span"
                >
                    <input type="text" formControlName="name" />
                </vdr-form-field>
                <vdr-form-field
                    for="workingTemperature"
                    label="{{ 'agromaat.agroflex.workingTemperature' | translate }}"
                >
                    <clr-input-container class="py-1">
                        <input
                            clrInput
                            type="number"
                            formControlName="workingTemperature"
                            type="number"
                            min="3"
                            step="0.5"
                            max="20"
                        />
                        <div class="ml-1" clrInputSuffix>
                            {{ 'agromaat.agroflex.temperatureUnit' | translate }}
                        </div>
                        <clr-control-helper>{{
                            'agromaat.agroflex.currentTemperatureMessage'
                                | translate: { temperature: (entity$ | async)?.temperature }
                        }}</clr-control-helper>
                    </clr-input-container>
                </vdr-form-field>
                <vdr-form-field for="isLightOn" label="{{ 'agromaat.agroflex.isLightOn' | translate }}">
                    <clr-toggle-wrapper>
                        <input name="isLightOn" formControlName="isLightOn" type="checkbox" clrToggle />
                    </clr-toggle-wrapper>
                </vdr-form-field>
            </div>

            <div class="btn-bar">
                <button class="btn btn-primary" [disabled]="detailForm.invalid" (click)="save()">
                    {{ 'common.update' | translate }}
                </button>
            </div>
        </form>
    </vdr-card>
</vdr-page-block>
