import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule, TypedBaseListComponent } from '@vendure/admin-ui/core';
import { GetMilktapModulesDocument } from '../../../generated-types';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'milktap-modules',
    templateUrl: './milktap-modules.component.html',
    styleUrls: ['./milktap-modules.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class MilktapModulesComponent extends TypedBaseListComponent<
    typeof GetMilktapModulesDocument,
    'milktapModules'
> {
    itemsPerPage$ = new BehaviorSubject(50);
    readonly filters = this.createFilterCollection().addIdFilter().connectToRoute(this.route);

    readonly sorts = this.createSortCollection()
        .defaultSort('createdAt', 'DESC')
        .addSort({ name: 'createdAt' })
        .addSort({ name: 'updatedAt' })
        .addSort({ name: 'id' })
        .connectToRoute(this.route);

    constructor() {
        super();
        super.configure({
            document: GetMilktapModulesDocument,
            getItems: data => ({
                items: data.milktapModules.items,
                totalItems: data.milktapModules.totalItems,
            }),
            setVariables: (skip, take) => ({
                options: {
                    skip,
                    take,
                    filter: {
                        ...this.filters.createFilterInput(),
                    },
                    sort: this.sorts.createSortInput(),
                },
            }),
            refreshListOnChanges: [this.filters.valueChanges, this.sorts.valueChanges],
        });
    }
}
