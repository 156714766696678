import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, Subject } from 'rxjs';
import { debounceTime, startWith, switchMap, tap } from 'rxjs/operators';
import { DataService, ModalService, NotificationService } from '@vendure/admin-ui/core';
import {
    BulkUpdateJofemarVisionEsPlusChannelProductVariantDocument,
    BulkUpdateJofemarVisionEsPlusChannelProductVariantMutation,
    BulkUpdateJofemarVisionEsPlusChannelStockDocument,
    DispenseJofemarVisionEsPlusChannelDocument,
    DispenseJofemarVisionEsPlusChannelQuery,
    GetJofemarVisionEsPlusTraysDocument,
    JofemarVisionEsPlusTrayFragment,
} from '../../generated-types';
import { ProductVariantSelectDialogComponent } from '../../components/support/product-variant-select-dialog/product-variant-select-dialog.component';
import { DeliverType } from '../../ui-types';

type Channel = JofemarVisionEsPlusTrayFragment['jofemarVisionEsPlusChannels'][0] & {
    updating?: boolean;
    previousStock?: number;
};

type Item = Omit<JofemarVisionEsPlusTrayFragment, 'jofemarVisionEsPlusChannels'> & {
    jofemarVisionEsPlusChannels: Channel[];
};

@Injectable()
export class JofemarVisionEsPlusTrayService {
    private itemsSubject = new BehaviorSubject<Item[] | null>(null);
    private loadingSubject = new BehaviorSubject<boolean>(true);

    trays$: Observable<Item[] | null> = this.itemsSubject.asObservable();
    loading$: Observable<boolean> = this.loadingSubject.asObservable();

    private debouncedStockUpdateSubject = new Subject<{ channelId: string; inStock: number }>();

    constructor(
        private readonly notificationService: NotificationService,
        private readonly dataService: DataService,
        private readonly modalService: ModalService,
    ) {
        this.debouncedStockUpdateSubject
            .pipe(
                debounceTime(300),
                switchMap(({ channelId, inStock }) => this.handleStockUpdate(channelId, inStock)),
            )
            .subscribe();
    }

    loadTrays(jofemarVisionEsPlusId: string): void {
        this.loadingSubject.next(true);
        this.fetchTrays(jofemarVisionEsPlusId).subscribe({
            next: items => this.handleLoadTraysSuccess(items),
            error: () => this.handleLoadTraysError(),
        });
    }

    optimisticUpdateStock(channelId: string, inStock: number) {
        const currentTrays = this.itemsSubject.value;
        if (currentTrays) {
            const updatedTrays: Item[] = currentTrays.map(tray => ({
                ...tray,
                jofemarVisionEsPlusChannels: tray.jofemarVisionEsPlusChannels.map(channel => {
                    if (channel.id === channelId) {
                        return {
                            ...channel,
                            inStock,
                            updating: true,
                            previousStock: channel.previousStock || channel.inStock,
                        };
                    }
                    return channel;
                }),
            }));

            this.itemsSubject.next(updatedTrays);
            this.debouncedStockUpdateSubject.next({ channelId, inStock });
        }
    }

    testDispense(channelId: string) {
        this.dataService
            .query(DispenseJofemarVisionEsPlusChannelDocument, { id: channelId })
            .mapSingle(res => this.handleTestDispenseResponse(res))
            .subscribe({
                error: () => this.handleTestDispenseError(),
            });
    }

    updateProductVariant(channelId: string, productVariantId?: string) {
        const channelIds = [channelId];
        this.modalService
            .fromComponent(ProductVariantSelectDialogComponent, {
                locals: {
                    cancellable: true,
                    deliverDevice: DeliverType.JOFEMAR_VISION_ES_PLUS,
                    excludeIds: productVariantId ? [productVariantId] : [],
                },
            })
            .subscribe(id => this.handleProductVariantSelection(id, channelIds));
    }

    private handleStockUpdate(channelId: string, inStock: number) {
        return this.updateStock(channelId, inStock).pipe(
            tap(() => this.updateTrayState(channelId)),
            catchError(error => {
                this.revertStockUpdate(channelId);
                return of(error);
            }),
        );
    }

    private updateTrayState(channelId: string) {
        const currentTrays = this.itemsSubject.value;
        if (!currentTrays || currentTrays.length === 0) {
            return;
        }

        const updatedTrays: JofemarVisionEsPlusTrayFragment[] = currentTrays.map(tray => ({
            ...tray,
            jofemarVisionEsPlusChannels: tray.jofemarVisionEsPlusChannels.map(channel => {
                if (channel.id === channelId) {
                    return { ...channel, updating: false, previousStock: undefined };
                }
                return channel;
            }),
        }));

        this.itemsSubject.next(updatedTrays);
    }

    private fetchTrays(jofemarVisionEsPlusId: string): Observable<JofemarVisionEsPlusTrayFragment[]> {
        return this.dataService
            .query(GetJofemarVisionEsPlusTraysDocument, {
                options: {
                    filter: {
                        jofemarVisionEsPlusId: { eq: jofemarVisionEsPlusId },
                    },
                },
            })
            .refetchOnChannelChange()
            .mapSingle(item => item.jofemarVisionEsPlusTrays.items)
            .pipe(startWith([]));
    }

    private handleLoadTraysSuccess(items: Item[]) {
        this.itemsSubject.next(items);
        if (items.length) {
            this.loadingSubject.next(false);
        }
    }

    private handleLoadTraysError() {
        this.itemsSubject.next([]);
        this.loadingSubject.next(false);
    }

    private handleTestDispenseResponse(res: DispenseJofemarVisionEsPlusChannelQuery) {
        if (!res) {
            this.notificationService.error('common.notify-update-error', { entity: 'AgroFlex kanaal' });
        }
    }

    private handleTestDispenseError() {
        this.notificationService.error('common.notify-update-error', { entity: 'AgroFlex kanaal' });
    }

    private updateStock(channelId: string, inStock: number) {
        const input = { ids: [channelId], inStock };
        return this.dataService.mutate(BulkUpdateJofemarVisionEsPlusChannelStockDocument, { input });
    }

    private revertStockUpdate(channelId: string) {
        const currentTrays = this.itemsSubject.value;
        if (currentTrays) {
            const revertedTrays = currentTrays.map(tray => ({
                ...tray,
                jofemarVisionEsPlusChannels: tray.jofemarVisionEsPlusChannels.map(channel => {
                    if (channel.id === channelId && typeof channel.previousStock === 'number') {
                        return { ...channel, inStock: channel.previousStock, updating: false };
                    }
                    return channel;
                }),
            }));

            this.itemsSubject.next(revertedTrays);
        }
    }

    private handleProductVariantSelection(productVariantIds: string[] | undefined, channelIds: string[]) {
        const currentTrays = this.itemsSubject.value;
        if (!productVariantIds || !currentTrays) {
            return;
        }

        this.dataService
            .mutate(BulkUpdateJofemarVisionEsPlusChannelProductVariantDocument, {
                input: {
                    ids: channelIds,
                    productVariantId: productVariantIds[0],
                },
            })
            .subscribe({
                next: result => this.handleProductVariantUpdateResult(result, currentTrays),
                error: () =>
                    this.notificationService.error('common.notify-update-error', {
                        entity: 'AgroFlex kanaal',
                    }),
            });
    }

    private handleProductVariantUpdateResult(
        result: BulkUpdateJofemarVisionEsPlusChannelProductVariantMutation,
        items: Item[],
    ) {
        if (
            result.bulkUpdateJofemarVisionEsPlusChannelProductVariant[0].__typename !==
            'JofemarVisionEsPlusChannel'
        ) {
            this.notificationService.error('common.notify-update-error', { entity: 'AgroFlex kanaal' });
            return;
        }

        const updated = result.bulkUpdateJofemarVisionEsPlusChannelProductVariant[0];
        const updatedItems = items.map(tray => ({
            ...tray,
            jofemarVisionEsPlusChannels: tray.jofemarVisionEsPlusChannels.map(channel => {
                if (channel.id === updated.id) {
                    return updated;
                }
                return channel;
            }),
        }));

        this.itemsSubject.next(updatedItems);
    }
}
