import { DataService, NotificationService, registerBulkAction } from '@vendure/admin-ui/core';
import { BulkOpenProductCellDocument, ProductCell } from '../../../generated-types';
import { Injector } from '@angular/core';
import { ProductCellsListComponent } from '../../../components/product-cell/product-cells-list/product-cells-list.component';
import { Locations } from '../constants';

export const openProductCell = registerBulkAction({
    location: Locations.productCellsList,
    label: 'agromaat.bulk.open-product-cell',
    icon: 'unlock',
    onClick: ({
        selection,
        injector,
        hostComponent,
    }: {
        selection: ProductCell[];
        injector: Injector;
        hostComponent: ProductCellsListComponent;
    }) => {
        const ids = selection.map(({ id }) => id);
        const dataService = injector.get(DataService);
        const notificationService = injector.get(NotificationService);
        dataService
            .mutate(BulkOpenProductCellDocument, {
                input: {
                    ids,
                },
            })
            .subscribe({
                next: result => {
                    hostComponent.refresh();
                    if (result.bulkOpenProductCell.length !== ids.length) {
                        notificationService.error('common.notify-update-error', {
                            entity: 'Productvak',
                        });
                        return;
                    }
                    notificationService.success('common.notify-update-success', {
                        entity: 'Productvak',
                    });
                },
                error: () =>
                    notificationService.error('common.notify-update-error', {
                        entity: 'Productvak',
                    }),
            });
    },
});
