import { DataService, ModalService, NotificationService, registerBulkAction } from '@vendure/admin-ui/core';
import {
    JofemarVisionEsPlusChannel,
    UpdateProductVariantInput,
    UpdateProductVariantsDocument,
} from '../../../generated-types';
import { Injector } from '@angular/core';
import { JofemarVisionEsPlusLayoutComponent } from '../../../components/jofemar-vision-es-plus/jofemar-vision-es-plus-layout/jofemar-vision-es-plus-layout.component';
import { Locations } from '../constants';
import { UpdateAdjustingPositionOfElevatorDialogComponent } from '../../../components/support/update-adjusting-position-of-elevator-dialog/update-adjusting-position-of-elevator-dialog.component';

export const changeJofemarVisionEsPlusProductVariantAdjustingPositionOfElevator = registerBulkAction({
    location: Locations.jofemarVisionEsPlusList,
    label: 'agromaat.bulk.change-product-variant-adjusting-position-of-elevator',
    icon: 'tag',
    onClick: ({
        selection,
        injector,
        hostComponent,
    }: {
        selection: JofemarVisionEsPlusChannel[];
        injector: Injector;
        hostComponent: JofemarVisionEsPlusLayoutComponent;
    }) => {
        const ids: string[] = Array.from(
            new Set(selection.map(({ productVariant }) => productVariant?.id).filter(Boolean)),
        ) as string[];
        const allNames = Array.from(new Set(selection.map(c => c.productVariant?.name)));
        const defaultAdjustingPositionOfElevator = selection[0]?.productVariant?.customFields
            ?.adjustingPositionOfElevator as 'UPPER' | 'LOWER';
        const defaultAdjustingTimePositionOfElevator =
            selection[0]?.productVariant?.customFields?.adjustingTimePositionOfElevator || 0;
        const itemNames = allNames.slice(0, 5).join(', ');
        const nMore = allNames.length > 5 ? allNames.length - 5 : 0;
        const modalService = injector.get(ModalService);
        const dataService = injector.get(DataService);
        const notificationService = injector.get(NotificationService);
        modalService
            .fromComponent(UpdateAdjustingPositionOfElevatorDialogComponent, {
                size: 'md',
                locals: {
                    values: {
                        adjustingPositionOfElevator: defaultAdjustingPositionOfElevator || 'UPPER',
                        adjustingTimePositionOfElevator: defaultAdjustingTimePositionOfElevator,
                    },
                    itemNames,
                    nMore,
                },
            })
            .subscribe(values => {
                if (values === undefined) {
                    return;
                }

                const input: UpdateProductVariantInput[] = ids.map(id => ({
                    id,
                    customFields: {
                        ...values,
                    },
                }));

                return dataService.mutate(UpdateProductVariantsDocument, { input }).subscribe({
                    next: result => {
                        hostComponent.refresh();
                        if (result.updateProductVariants.length !== ids.length) {
                            notificationService.error('common.notify-update-error', {
                                entity: 'ProductVariant',
                            });
                            return;
                        }
                        notificationService.success('common.notify-update-success', {
                            entity: 'ProductVariant',
                        });
                    },
                    error: () =>
                        notificationService.error('common.notify-update-error', {
                            entity: 'ProductVariant',
                        }),
                });
            });
    },
});
