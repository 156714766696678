<vdr-page-block>
    <vdr-action-bar>
        <vdr-ab-right>
            <div class="flex clr-row action-buttons-container items-center">
                <device-dropdown
                    [devices]="jofemarVisionEsPlusCollection$ | async"
                    [selectedDevice]="selectedJofemarVisionEsPlus"
                    (deviceSelected)="selectJofemarVisionEsPlus($event)"
                ></device-dropdown>
            </div>
        </vdr-ab-right>
    </vdr-action-bar>
</vdr-page-block>

<vdr-data-table-2
    id="jofemar-vision-es-plus-list"
    [items]="items$ | async"
    [itemsPerPage]="itemsPerPage$ | async"
    [totalItems]="totalItems$ | async"
    [currentPage]="currentPage$ | async"
    (pageChange)="setPageNumber($event)"
    (itemsPerPageChange)="setItemsPerPage($event)"
>
    <vdr-bulk-action-menu
        locationId="jofemar-vision-es-plus-list"
        [hostComponent]="this"
        [selectionManager]="selectionManager"
    />
    <!-- Here we define all the available columns -->
    <vdr-dt2-column [heading]="'common.id' | translate" [hiddenByDefault]="true">
        <ng-template let-jofemarVisionEsPlusChannel="item">{{ jofemarVisionEsPlusChannel.id }}</ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.agroflex.channelAlias' | translate">
        <ng-template let-jofemarVisionEsPlusChannel="item">{{
            jofemarVisionEsPlusChannel.channelAlias
        }}</ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'common.image' | translate" id="image">
        <ng-template let-jofemarVisionEsPlusChannel="item">
            <div class="image-placeholder">
                <img
                    *ngIf="
                        jofemarVisionEsPlusChannel.productVariant?.featuredAsset ||
                            jofemarVisionEsPlusChannel.productVariant?.product.featuredAsset as asset;
                        else imagePlaceholder
                    "
                    [src]="asset | assetPreview: 'tiny'"
                />
                <ng-template #imagePlaceholder>
                    <div class="placeholder">
                        <clr-icon shape="image" size="48"></clr-icon>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'catalog.variant' | translate">
        <ng-template let-jofemarVisionEsPlusChannel="item">
            {{ jofemarVisionEsPlusChannel.productVariant?.name || 'Geen product gekoppeld' }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'catalog.stock-on-hand' | translate">
        <ng-template let-jofemarVisionEsPlusChannel="item">
            {{ jofemarVisionEsPlusChannel.inStock }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.agroflex.isFragile' | translate">
        <ng-template let-jofemarVisionEsPlusChannel="item">
            <clr-icon
                size="20"
                *ngIf="jofemarVisionEsPlusChannel.productVariant?.customFields.isFragile === true"
                shape="check"
            ></clr-icon>
            <clr-icon
                size="20"
                *ngIf="jofemarVisionEsPlusChannel.productVariant?.customFields.isFragile === false"
                shape="circle"
            ></clr-icon>
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.agroflex.adjustingPositionOfElevator' | translate">
        <ng-template let-jofemarVisionEsPlusChannel="item">
            {{
                jofemarVisionEsPlusChannel.productVariant?.customFields.adjustingTimePositionOfElevator || '-'
            }}
            <clr-icon
                size="20"
                *ngIf="
                    jofemarVisionEsPlusChannel.productVariant?.customFields.adjustingPositionOfElevator ===
                        'UPPER' &&
                    jofemarVisionEsPlusChannel.productVariant?.customFields.adjustingTimePositionOfElevator >
                        0
                "
                shape="arrow"
                class="elevator-position-icon"
            ></clr-icon>
            <clr-icon
                size="20"
                *ngIf="
                    jofemarVisionEsPlusChannel.productVariant?.customFields.adjustingPositionOfElevator ===
                        'LOWER' &&
                    jofemarVisionEsPlusChannel.productVariant?.customFields.adjustingTimePositionOfElevator >
                        0
                "
                shape="arrow"
                class="elevator-position-icon rotate-icon"
            ></clr-icon>
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.agroflex.dispensingSpeed' | translate">
        <ng-template let-jofemarVisionEsPlusChannel="item">
            {{ jofemarVisionEsPlusChannel.productVariant?.customFields.dispensingSpeed || '-' }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.agroflex.postDispenseChannelTime' | translate">
        <ng-template let-jofemarVisionEsPlusChannel="item">
            {{ jofemarVisionEsPlusChannel.productVariant?.customFields.postDispenseChannelTime || '-' }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'common.created-at' | translate" id="created-at" [hiddenByDefault]="true">
        <ng-template let-jofemarVisionEsPlusChannel="item">
            {{ jofemarVisionEsPlusChannel.createdAt | localeDate: 'short' }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'common.updated-at' | translate" id="updated-at" [hiddenByDefault]="true">
        <ng-template let-jofemarVisionEsPlusChannel="item">
            {{ jofemarVisionEsPlusChannel.updatedAt | localeDate: 'short' }}
        </ng-template>
    </vdr-dt2-column>
</vdr-data-table-2>
