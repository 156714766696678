<vdr-page-detail-layout>
    <div *ngFor="let jofemarVisionEsPlus of jofemarVisionEsPlusCollection$ | async">
        <jofemar-vision-es-plus-detail-form-setting
            [id]="jofemarVisionEsPlus.id"
        ></jofemar-vision-es-plus-detail-form-setting>
    </div>
    <vdr-page-detail-sidebar>
        <div *ngFor="let jofemarVisionEsPlus of jofemarVisionEsPlusCollection$ | async">
            <vdr-card [title]="jofemarVisionEsPlus?.name" class="mb-3">
                <div *ngIf="jofemarVisionEsPlus?.faultCodes.length !== 0" class="mb-2">
                    <div *ngFor="let faultCode of jofemarVisionEsPlus.faultCodes" class="my-1">
                        <clr-alert
                            [clrAlertType]="'danger'"
                            [clrAlertAppLevel]="true"
                            [clrAlertClosable]="false"
                        >
                            <clr-alert-item>
                                <span class="alert-text">{{
                                    'agromaat.agroflex.error.' + faultCode | translate
                                }}</span>
                            </clr-alert-item>
                        </clr-alert>
                    </div>
                </div>
                <vdr-page-entity-info [entity]="jofemarVisionEsPlus" />
                <div class="my-1" *ngIf="jofemarVisionEsPlus.availability !== null">
                    <label>{{ 'agromaat.agroflex.availabilityStatus' | translate }}</label>
                    <div class="mt-1 flex" style="gap: 12px">
                        <vdr-chip
                            colorType="success"
                            *ngIf="jofemarVisionEsPlus.availability === 'MACHINE_FREE'"
                        >
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>{{ 'agromaat.agroflex.availabilityMachineFree' | translate }}</span>
                        </vdr-chip>
                        <vdr-chip
                            colorType="warning"
                            *ngIf="jofemarVisionEsPlus.availability === 'MACHINE_BUSY'"
                        >
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>{{ 'agromaat.agroflex.availabilityMachineBusy' | translate }}</span>
                        </vdr-chip>
                        <vdr-chip
                            colorType="warning"
                            *ngIf="jofemarVisionEsPlus.availability === 'DISPENSING'"
                        >
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>{{ 'agromaat.agroflex.availabilityDispensing' | translate }}</span>
                        </vdr-chip>
                        <vdr-chip
                            colorType="warning"
                            *ngIf="jofemarVisionEsPlus.availability === 'ELEVATOR_COLLECTING_POSITION'"
                        >
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>{{
                                'agromaat.agroflex.availabilityElevatorCollectingPosition' | translate
                            }}</span>
                        </vdr-chip>
                        <vdr-chip
                            colorType="warning"
                            *ngIf="jofemarVisionEsPlus.availability === 'PRODUCT_DETECTOR_BLOCKED'"
                        >
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>{{
                                'agromaat.agroflex.availabilityProductDetectorBlocked' | translate
                            }}</span>
                        </vdr-chip>
                    </div>
                </div>
                <div class="mb-1">
                    <label>{{ 'agromaat.agroflex.connectionStatus' | translate }}</label>
                    <div class="mt-1 flex" style="gap: 12px">
                        <vdr-chip
                            colorType="success"
                            *ngIf="jofemarVisionEsPlus.connectionStatus === 'ONLINE'"
                        >
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>Online</span>
                        </vdr-chip>
                        <vdr-chip
                            colorType="error"
                            *ngIf="jofemarVisionEsPlus.connectionStatus === 'OFFLINE'"
                        >
                            <clr-icon shape="is-solid"></clr-icon>
                            <span>Offline</span>
                        </vdr-chip>
                    </div>
                </div>
            </vdr-card>
        </div>
    </vdr-page-detail-sidebar>
</vdr-page-detail-layout>
