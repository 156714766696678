import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    GetJofemarNuukTraysQuery,
    JofemarNuukFragment,
    JofemarNuukTrayFragment,
} from '../../../generated-types';
import { PermissionsService, SharedModule } from '@vendure/admin-ui/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DeviceDropdownComponent } from '../../support/device-dropdown/device-dropdown.component';
import { JofemarNuukCollectionService } from '../../../services/jofemar-nuuk/jofemar-nuuk-collection.service';
import { JofemarNuukTrayService } from '../../../services/jofemar-nuuk/jofemar-nuuk-tray.service';

type Modes = 'home' | 'edit' | 'increase' | 'decrease' | 'test-dispense';

@Component({
    selector: 'jofemar-nuuk-layout',
    templateUrl: './jofemar-nuuk-layout.component.html',
    styleUrls: ['./jofemar-nuuk-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule, DeviceDropdownComponent],
})
export class JofemarNuukLayoutComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    jofemarNuukCollection$ = this.jofemarNuukCollectionService.jofemarNuukCollection$;
    selectedJofemarNuuk: JofemarNuukFragment;
    items: JofemarNuukTrayFragment[];
    private selectedJofemarNuukId$ = new BehaviorSubject<string | undefined>(undefined);
    items$ = this.jofemarNuukTrayService.trays$;
    loading$ = this.jofemarNuukTrayService.loading$;
    panels: { [key: string]: boolean } = {};
    @Input() mode: Modes = 'home';
    modeOptions = [
        {
            id: 'home',
            value: 'home',
            title: 'Bekijk voorraad',
            iconShape: 'home',
            iconDirection: '',
        },
        {
            id: 'increase',
            value: 'increase',
            title: 'Verhoog voorraad',
            iconShape: 'arrow',
            iconDirection: '',
        },
        {
            id: 'decrease',
            value: 'decrease',
            title: 'Verlaag voorraad',
            iconShape: 'arrow',
            iconDirection: 'down',
        },
        {
            id: 'edit',
            value: 'edit',
            title: 'Wijzig product',
            iconShape: 'pencil',
            iconDirection: '',
        },
        ...[
            this.permissionsService.userHasPermissions(['SuperAdmin'])
                ? {
                      id: 'test-dispense',
                      value: 'test-dispense',
                      title: 'Test uitgifte',
                      iconShape: 'check',
                      iconDirection: '',
                  }
                : null,
        ].filter(Boolean),
    ];

    constructor(
        private readonly permissionsService: PermissionsService,
        private readonly jofemarNuukCollectionService: JofemarNuukCollectionService,
        private readonly jofemarNuukTrayService: JofemarNuukTrayService,
    ) {}

    ngOnInit(): void {
        this.jofemarNuukCollectionService.getSelectedJofemarNuukId().subscribe(id => {
            if (!id) {
                return;
            }

            this.jofemarNuukCollection$.subscribe(jofemarNuukCollection => {
                const found = jofemarNuukCollection?.find(d => d.id === id);
                if (found) {
                    this.selectJofemarNuuk(found);
                }
            });
        });
    }

    setMode(mode: Modes): void {
        this.mode = mode;
    }

    togglePanel(panel: string): void {
        this.panels[panel] = !this.panels[panel];
    }

    trackByJofemarNuukTrayId(
        index: number,
        item: GetJofemarNuukTraysQuery['jofemarNuukTrays']['items'][0],
    ): string {
        return item.id;
    }

    trackByJofemarNuukChannelId(
        index: number,
        item: GetJofemarNuukTraysQuery['jofemarNuukTrays']['items'][0],
    ): string {
        return item.id;
    }

    resetFaults(): void {
        this.jofemarNuukCollectionService.resetFaults();
    }

    syncJofemarNuuk(): void {
        this.jofemarNuukCollectionService.syncJofemarNuuk();
    }

    refresh(): void {
        this.jofemarNuukTrayService.loadTrays(this.selectedJofemarNuuk.id);
    }

    update({
        id,
        inStock,
        productVariant,
    }: {
        id: string;
        inStock: number;
        productVariant?: { id: string };
    }): void {
        if (this.mode === 'edit') {
            this.jofemarNuukTrayService.updateProductVariant(id, productVariant?.id);
            return;
        }

        if (this.mode === 'test-dispense') {
            this.jofemarNuukTrayService.testDispense(id);
            return;
        }

        const newStock = Math.max(0, inStock + (this.mode === 'increase' ? 1 : -1));
        this.jofemarNuukTrayService.optimisticUpdateStock(id, newStock);
    }

    selectJofemarNuuk(jofemarNuuk: JofemarNuukFragment) {
        if (this.selectedJofemarNuukId$.getValue() === jofemarNuuk.id) {
            return;
        }
        this.jofemarNuukCollectionService.setSelectedJofemarNuukId(jofemarNuuk.id);
        this.selectedJofemarNuuk = jofemarNuuk;
        this.selectedJofemarNuukId$.next(jofemarNuuk.id);
        this.refresh();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
