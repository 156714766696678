import { JofemarNuuk, RestartJofemarNuukDocument } from '../../../generated-types';
import { BulkActionClickContext, DataService, NotificationService } from '@vendure/admin-ui/core';

export const restartJofemarNuukModule = ({
    selection,
    injector,
}: BulkActionClickContext<JofemarNuuk, unknown>) => {
    const ids = selection.map(({ id }) => id);
    const dataService = injector.get(DataService);
    const notificationService = injector.get(NotificationService);
    dataService
        .mutate(RestartJofemarNuukDocument, {
            ids,
        })
        .subscribe({
            next: () => {
                notificationService.success('Jofemars worden herstart');
            },
            error: err => {
                notificationService.error(err);
            },
        });
};
