<vdr-dropdown *ngIf="devices?.length > 1">
    <button type="button" class="btn button button-select-device" vdrDropdownTrigger>
        {{ selectedDevice.name }}
        <clr-icon shape="caret down"></clr-icon>
    </button>
    <vdr-dropdown-menu vdrPosition="bottom-right">
        <button
            type="button"
            class="language-option"
            *ngFor="let device of devices"
            (click)="selectDevice(device)"
            vdrDropdownItem
        >
            <span> {{ device.name }} </span>
        </button>
    </vdr-dropdown-menu>
</vdr-dropdown>
