import gql from 'graphql-tag';

const ASSET_FRAGMENT = gql`
    fragment Asset on Asset {
        id
        name
        fileSize
        mimeType
        type
        preview
        source
    }
`;

export const PRODUCT_CELL_FRAGMENT = gql`
    fragment ProductCell on ProductCell {
        id
        createdAt
        updatedAt
        col
        row
        state
        type
        enabled
        productVariant {
            id
            name
            featuredAsset {
                ...Asset
            }
            product {
                featuredAsset {
                    ...Asset
                }
            }
            customFields {
                productCellTypes
            }
        }
        productModule {
            id
            name
            connectivity {
                connectivityStatus
                lastConnectivityUpdate
            }
            vending {
                cooling
            }
        }
    }

    ${ASSET_FRAGMENT}
`;

export const JOFEMAR_VISION_ES_PLUS_FRAGMENT = gql`
    fragment JofemarVisionEsPlus on JofemarVisionEsPlus {
        id
        name
        machineAddress {
            espSerialNumber
            port
        }
        isSyncing
        availability
        connectionStatus
        faultCodes
    }
`;

export const JOFEMAR_VISION_ES_PLUS_CHANNEL_FRAGMENT = gql`
    fragment JofemarVisionEsPlusChannel on JofemarVisionEsPlusChannel {
        id
        inStock
        channelAlias
        sequence
        channelNumber
        productVariant {
            id
            name
            featuredAsset {
                ...Asset
            }
            product {
                featuredAsset {
                    ...Asset
                }
            }
        }
        tray {
            id
        }
        channels {
            id
        }
        createdAt
        updatedAt
    }

    ${ASSET_FRAGMENT}
`;

export const JOFEMAR_VISION_ES_PLUS_TRAY_FRAGMENT = gql`
    fragment JofemarVisionEsPlusTray on JofemarVisionEsPlusTray {
        id
        trayNumber
        sequence
        jofemarVisionEsPlusChannels {
            ...JofemarVisionEsPlusChannel
        }
    }

    ${JOFEMAR_VISION_ES_PLUS_CHANNEL_FRAGMENT}
`;

export const JOFEMAR_NUUK_FRAGMENT = gql`
    fragment JofemarNuuk on JofemarNuuk {
        id
        name
        isSyncing
        connectionStatus
        availability
        faultCodes
    }
`;

export const JOFEMAR_NUUK_CHANNEL_FRAGMENT = gql`
    fragment JofemarNuukChannel on JofemarNuukChannel {
        id
        inStock
        channelAlias
        sequence
        channelNumber
        productVariant {
            id
            name
            featuredAsset {
                ...Asset
            }
            product {
                featuredAsset {
                    ...Asset
                }
            }
        }
        channels {
            id
        }
        createdAt
        updatedAt
    }

    ${ASSET_FRAGMENT}
`;

export const JOFEMAR_NUUK_TRAY_FRAGMENT = gql`
    fragment JofemarNuukTray on JofemarNuukTray {
        id
        trayNumber
        sequence
        jofemarNuukChannels {
            ...JofemarNuukChannel
        }
    }

    ${JOFEMAR_NUUK_CHANNEL_FRAGMENT}
`;
