import { DataService, ModalService, NotificationService, registerBulkAction } from '@vendure/admin-ui/core';
import { BulkUpdateProductCellStateDocument, ProductCell } from '../../../generated-types';
import { Injector } from '@angular/core';
import { ProductCellsListComponent } from '../../../components/product-cell/product-cells-list/product-cells-list.component';
import { ProductCellStateSelectDialogComponent } from '../../../components/product-cell/product-cell-state-select-dialog/product-cell-state-select-dialog.component';
import { Locations } from '../constants';

export const changeProductCellState = registerBulkAction({
    location: Locations.productCellsList,
    label: 'agromaat.bulk.change-product-cell-state',
    icon: 'edit',
    onClick: ({
        selection,
        injector,
        hostComponent,
    }: {
        selection: ProductCell[];
        injector: Injector;
        hostComponent: ProductCellsListComponent;
    }) => {
        const ids = selection.map(({ id }) => id);
        const modalService = injector.get(ModalService);
        const dataService = injector.get(DataService);
        const notificationService = injector.get(NotificationService);
        modalService
            .fromComponent(ProductCellStateSelectDialogComponent, {
                locals: {
                    cancellable: true,
                },
            })
            .subscribe(res => {
                if (!res) {
                    return;
                }

                return dataService
                    .mutate(BulkUpdateProductCellStateDocument, {
                        input: {
                            ids,
                            state: res,
                        },
                    })
                    .subscribe({
                        next: result => {
                            hostComponent.refresh();
                            if (result.bulkUpdateProductCellState.length !== ids.length) {
                                notificationService.error('common.notify-update-error', {
                                    entity: 'Productvak',
                                });
                                return;
                            }
                            notificationService.success('common.notify-update-success', {
                                entity: 'Productvak',
                            });
                        },
                        error: () =>
                            notificationService.error('common.notify-update-error', {
                                entity: 'Productvak',
                            }),
                    });
            });
    },
});
