import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, interval, merge, Observable, of, timer } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { DataService, ModalService, NotificationService, PermissionsService } from '@vendure/admin-ui/core';
import {
    GetJofemarVisionEsPlusCollectionDocument,
    JofemarVisionEsPlusFragment,
    ResetFaultsJofemarVisionEsPlusDocument,
    ResetJofemarVisionEsPlusDocument,
    SortOrder,
    SyncJofemarVisionEsPlusDocument,
} from '../../generated-types';

@Injectable()
export class JofemarVisionEsPlusCollectionService {
    private refreshCollection$ = new BehaviorSubject<void>(undefined);
    private selectedJofemarVisionEsPlusId$ = new BehaviorSubject<string | undefined>(undefined);
    jofemarVisionEsPlusCollection$: Observable<JofemarVisionEsPlusFragment[]>;

    constructor(
        private readonly dataService: DataService,
        private readonly permissionsService: PermissionsService,
        private readonly modalService: ModalService,
        private readonly notificationService: NotificationService,
    ) {
        this.jofemarVisionEsPlusCollection$ = this.refreshCollection$.pipe(
            switchMap(() => this.pollForCollection()),
        );
    }

    private pollForCollection(): Observable<JofemarVisionEsPlusFragment[]> {
        return merge(timer(0), interval(5000)).pipe(
            switchMap(() =>
                this.permissionsService.userHasPermissions(['ManageJofemarVisionEsPlusStock'])
                    ? this.fetchJofemarVisionEsPlusCollection()
                    : of([]),
            ),
            takeWhile(
                collection =>
                    collection !== null &&
                    collection.some(
                        el => this.selectedJofemarVisionEsPlusId$.getValue() === el.id && el.isSyncing,
                    ),
                true,
            ),
        );
    }

    private fetchJofemarVisionEsPlusCollection(): Observable<JofemarVisionEsPlusFragment[]> {
        return this.dataService
            .query(GetJofemarVisionEsPlusCollectionDocument, {
                options: {
                    sort: {
                        name: SortOrder.ASC,
                    },
                },
            })
            .refetchOnChannelChange()
            .mapSingle(data => data.jofemarVisionEsPlusCollection.items);
    }

    refresh() {
        this.refreshCollection$.next();
    }

    setSelectedJofemarVisionEsPlusId(id: string) {
        this.selectedJofemarVisionEsPlusId$.next(id);
    }

    getSelectedJofemarVisionEsPlusId(): Observable<string | undefined> {
        return this.selectedJofemarVisionEsPlusId$.asObservable();
    }

    resetFaults(): void {
        this.executeAction(
            'agromaat.dialog.reset-faults-agroflex.title',
            'agromaat.dialog.reset-faults-agroflex.body',
            ResetFaultsJofemarVisionEsPlusDocument,
            'agromaat.dialog.reset-faults-agroflex.success',
            'agromaat.dialog.reset-faults-agroflex.failed',
        );
    }

    resetToFactorySettings(): void {
        this.executeAction(
            'agromaat.dialog.reset-to-factory-settings-agroflex.title',
            'agromaat.dialog.reset-to-factory-settings-agroflex.body',
            ResetJofemarVisionEsPlusDocument,
            'agromaat.dialog.reset-to-factory-settings-agroflex.success',
            'agromaat.dialog.reset-to-factory-settings-agroflex.failed',
        );
    }

    syncJofemarVisionEsPlus(): void {
        this.executeAction(
            'agromaat.dialog.start-sync-agroflex.title',
            'agromaat.dialog.start-sync-agroflex.body',
            SyncJofemarVisionEsPlusDocument,
            'agromaat.dialog.start-sync-agroflex.success',
            'agromaat.dialog.start-sync-agroflex.failed',
        );
    }

    private executeAction(
        title: string,
        body: string,
        mutationDocument:
            | typeof ResetFaultsJofemarVisionEsPlusDocument
            | typeof ResetJofemarVisionEsPlusDocument
            | typeof SyncJofemarVisionEsPlusDocument,
        successMessage: string,
        errorMessage: string,
    ) {
        const jofemarVisionEsPlusId = this.selectedJofemarVisionEsPlusId$.value;
        if (!jofemarVisionEsPlusId) {
            return;
        }

        this.confirmAction(title, body)
            .pipe(
                switchMap(response =>
                    response ? this.performMutation(mutationDocument, jofemarVisionEsPlusId) : EMPTY,
                ),
            )
            .subscribe(result => {
                if (result.action.success) {
                    this.notificationService.success(successMessage);
                    this.refresh();
                    return;
                }
                this.notificationService.error(errorMessage);
            });
    }

    private confirmAction(title: string, body: string) {
        return combineLatest([
            this.modalService.dialog({
                title,
                buttons: [
                    { label: 'common.cancel', type: 'secondary' },
                    { label: 'common.confirm', type: 'primary', returnValue: true },
                ],
                body,
            }),
        ]).pipe(switchMap(([response]) => of(response)));
    }

    private performMutation(
        mutationDocument:
            | typeof ResetFaultsJofemarVisionEsPlusDocument
            | typeof ResetJofemarVisionEsPlusDocument
            | typeof SyncJofemarVisionEsPlusDocument,
        jofemarVisionEsPlusId: string,
    ) {
        return this.dataService.mutate(mutationDocument, { input: { jofemarVisionEsPlusId } });
    }
}
