import { JofemarVisionEsPlus, MeasureJofemarVisionEsPlusDocument } from '../../../generated-types';
import { BulkActionClickContext, DataService, NotificationService } from '@vendure/admin-ui/core';
import { lastValueFrom } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const measureJofemarVisionEsPlusModule = async ({
    selection,
    injector,
}: BulkActionClickContext<JofemarVisionEsPlus, unknown>) => {
    const ids = selection.map(({ id }) => id);
    const dataService = injector.get(DataService);
    const notificationService = injector.get(NotificationService);
    for (const id of ids) {
        try {
            const result = await lastValueFrom(
                dataService.query(MeasureJofemarVisionEsPlusDocument, {
                    id,
                }).single$,
            );

            notificationService.success(_('agromaat.bulk.measure-jofemar-vision-es-plus-success'), {
                id,
                chipTemperature: result.measureJofemarVisionEsPlus?.lastMeasuredChipTemperature || 0,
            });
        } catch {
            notificationService.error(_('agromaat.bulk.measure-jofemar-vision-es-plus-failed'));
        }
    }
};
