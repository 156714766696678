import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DefaultFormComponentConfig } from '@vendure/common/lib/shared-types';
import { Observable } from 'rxjs';
import { DataService, FormInputComponent, SharedModule } from '@vendure/admin-ui/core';
import { LanguageCode } from '@vendure/core';
import { AvailableProductModuleTypesDocument } from '../../../generated-types';

@Component({
    selector: 'vdr-deliver-device-form-input',
    templateUrl: './deliver-device-form-input.component.html',
    styleUrls: ['./deliver-device-form-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class DeliverDeviceFormInputComponent implements FormInputComponent, OnInit {
    static readonly id = 'deliver-device-form-input';
    @Input() readonly: boolean;
    formControl: UntypedFormControl;
    config: DefaultFormComponentConfig<'deliver-device-form-input'>;
    uiLanguage$: Observable<LanguageCode>;
    private _options: Record<string, unknown>[];

    get options() {
        return this._options;
    }

    constructor(
        private dataService: DataService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.uiLanguage$ = this.dataService.client.uiState().mapStream(({ uiState }) => uiState.language);
        this.dataService
            .query(AvailableProductModuleTypesDocument)
            .mapStream(({ availableProductModuleTypes }) => availableProductModuleTypes)
            .subscribe(availableProductModuleTypes => {
                this._options =
                    this.config.options ||
                    (this.config.ui?.options || this.config.options).filter(
                        (option: Record<string, unknown>) =>
                            availableProductModuleTypes.some(
                                moduleType =>
                                    moduleType.toLowerCase().replace(/_/g, '-') ===
                                    (option.value as string).toLowerCase(),
                            ),
                    );
                this.changeDetector.detectChanges();
            });
    }

    trackByFn(index: number, item: any) {
        return item.value;
    }
}
