import { restartModules } from './restart-modules';
import { measureModules } from './measure-modules';
import { openProductCell } from './open-product-cell';
import { changeProductVariantActions } from './change-product-variant';
import { changeProductCellState } from './change-product-cell-state';
import { changeStockActions } from './change-stock';

export const bulkActions = [
    ...restartModules,
    ...measureModules,
    ...changeStockActions,
    ...changeProductVariantActions,
    openProductCell,
    changeProductCellState,
];
