import { addNavMenuItem, addNavMenuSection } from '@vendure/admin-ui/core';

export const navMenuSections = [
    addNavMenuSection(
        {
            id: 'agromaat',
            label: 'agromaat.common.agromaat',
            items: [
                {
                    id: 'agrobox-layout',
                    label: 'agromaat.common.agrobox',
                    routerLink: ['/extensions/agromaat-stock/agrobox-layout'],
                    requiresPermission: 'ManageVendingStock',
                },
                {
                    id: 'agroflex-layout',
                    label: 'agromaat.agroflex.agroflex',
                    routerLink: ['/extensions/agromaat-stock/agroflex'],
                    requiresPermission: 'ManageJofemarVisionEsPlusStock',
                },
                {
                    id: 'agrofreeze-layout',
                    label: 'agromaat.agrofreeze.agrofreeze',
                    routerLink: ['/extensions/agromaat-stock/agrofreeze'],
                    requiresPermission: 'ManageJofemarNuukStock',
                },
                {
                    id: 'agrotap',
                    label: 'agromaat.common.agrotap',
                    routerLink: ['/extensions/agromaat-stock/agrotap'],
                    requiresPermission: 'ManageMilktapStock',
                },
            ],
        },
        'catalog',
    ),
    addNavMenuItem(
        {
            id: 'product-modules',
            label: 'agromaat.common.productModules',
            routerLink: ['/extensions/agromaat-stock/product-modules/agrobox'],
            requiresPermission: 'SuperAdmin',
        },
        'settings',
    ),
    addNavMenuItem(
        {
            id: 'graphql-sandbox',
            label: 'Apollo Sandbox',
            routerLink: ['/extensions/agromaat-stock/graphql-sandbox'],
            requiresPermission: 'SuperAdmin',
        },
        'settings',
    ),
];
