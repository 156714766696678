import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    GetJofemarVisionEsPlusTraysQuery,
    JofemarVisionEsPlusFragment,
    JofemarVisionEsPlusTrayFragment,
} from '../../../generated-types';
import { PermissionsService, SharedModule } from '@vendure/admin-ui/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DeviceDropdownComponent } from '../../support/device-dropdown/device-dropdown.component';
import { JofemarVisionEsPlusCollectionService } from '../../../services/jofemar-vision-es-plus/jofemar-vision-es-plus-collection.service';
import { JofemarVisionEsPlusTrayService } from '../../../services/jofemar-vision-es-plus/jofemar-vision-es-plus-tray.service';

type Modes = 'home' | 'edit' | 'increase' | 'decrease' | 'test-dispense';

@Component({
    selector: 'jofemar-vision-es-plus-layout',
    templateUrl: './jofemar-vision-es-plus-layout.component.html',
    styleUrls: ['./jofemar-vision-es-plus-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule, DeviceDropdownComponent],
})
export class JofemarVisionEsPlusLayoutComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    jofemarVisionEsPlusCollection$ = this.jofemarVisionEsPlusCollectionService.jofemarVisionEsPlusCollection$;
    selectedJofemarVisionEsPlus: JofemarVisionEsPlusFragment;
    items: JofemarVisionEsPlusTrayFragment[];
    private selectedJofemarVisionEsPlusId$ = new BehaviorSubject<string | undefined>(undefined);
    items$ = this.jofemarVisionEsPlusTrayService.trays$;
    loading$ = this.jofemarVisionEsPlusTrayService.loading$;
    panels: { [key: string]: boolean } = {};
    @Input() mode: Modes = 'home';
    modeOptions = [
        {
            id: 'home',
            value: 'home',
            title: 'Bekijk voorraad',
            iconShape: 'home',
            iconDirection: '',
        },
        {
            id: 'increase',
            value: 'increase',
            title: 'Verhoog voorraad',
            iconShape: 'arrow',
            iconDirection: '',
        },
        {
            id: 'decrease',
            value: 'decrease',
            title: 'Verlaag voorraad',
            iconShape: 'arrow',
            iconDirection: 'down',
        },
        {
            id: 'edit',
            value: 'edit',
            title: 'Wijzig product',
            iconShape: 'pencil',
            iconDirection: '',
        },
        ...[
            this.permissionsService.userHasPermissions(['SuperAdmin'])
                ? {
                      id: 'test-dispense',
                      value: 'test-dispense',
                      title: 'Test uitgifte',
                      iconShape: 'check',
                      iconDirection: '',
                  }
                : null,
        ].filter(Boolean),
    ];

    constructor(
        private readonly permissionsService: PermissionsService,
        private readonly jofemarVisionEsPlusCollectionService: JofemarVisionEsPlusCollectionService,
        private readonly jofemarVisionEsPlusTrayService: JofemarVisionEsPlusTrayService,
    ) {}

    ngOnInit(): void {
        this.jofemarVisionEsPlusCollectionService.getSelectedJofemarVisionEsPlusId().subscribe(id => {
            if (!id) {
                return;
            }

            this.jofemarVisionEsPlusCollection$.subscribe(jofemarVisionEsPlusCollection => {
                const found = jofemarVisionEsPlusCollection?.find(d => d.id === id);
                if (found) {
                    this.selectJofemarVisionEsPlus(found);
                }
            });
        });
    }

    setMode(mode: Modes): void {
        this.mode = mode;
    }

    togglePanel(panel: string): void {
        this.panels[panel] = !this.panels[panel];
    }

    trackByJofemarVisionEsPlusTrayId(
        index: number,
        item: GetJofemarVisionEsPlusTraysQuery['jofemarVisionEsPlusTrays']['items'][0],
    ): string {
        return item.id;
    }

    trackByJofemarVisionEsPlusChannelId(
        index: number,
        item: GetJofemarVisionEsPlusTraysQuery['jofemarVisionEsPlusTrays']['items'][0],
    ): string {
        return item.id;
    }

    resetFaults(): void {
        this.jofemarVisionEsPlusCollectionService.resetFaults();
    }

    resetToFactorySettings(): void {
        this.jofemarVisionEsPlusCollectionService.resetToFactorySettings();
    }

    syncJofemarVisionEsPlus(): void {
        this.jofemarVisionEsPlusCollectionService.syncJofemarVisionEsPlus();
    }

    refresh(): void {
        this.jofemarVisionEsPlusTrayService.loadTrays(this.selectedJofemarVisionEsPlus.id);
    }

    update({
        id,
        inStock,
        productVariant,
    }: {
        id: string;
        inStock: number;
        productVariant?: { id: string };
    }): void {
        if (this.mode === 'edit') {
            this.jofemarVisionEsPlusTrayService.updateProductVariant(id, productVariant?.id);
            return;
        }

        if (this.mode === 'test-dispense') {
            this.jofemarVisionEsPlusTrayService.testDispense(id);
            return;
        }

        const newStock = Math.max(0, inStock + (this.mode === 'increase' ? 1 : -1));
        this.jofemarVisionEsPlusTrayService.optimisticUpdateStock(id, newStock);
    }

    selectJofemarVisionEsPlus(jofemarVisionEsPlus: JofemarVisionEsPlusFragment) {
        if (this.selectedJofemarVisionEsPlusId$.getValue() === jofemarVisionEsPlus.id) {
            return;
        }
        this.jofemarVisionEsPlusCollectionService.setSelectedJofemarVisionEsPlusId(jofemarVisionEsPlus.id);
        this.selectedJofemarVisionEsPlus = jofemarVisionEsPlus;
        this.selectedJofemarVisionEsPlusId$.next(jofemarVisionEsPlus.id);
        this.refresh();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
