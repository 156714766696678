<vdr-page-detail-layout>
    <div *ngFor="let jofemarNuuk of jofemarNuukCollection$ | async">
        <jofemar-nuuk-detail-form-setting [id]="jofemarNuuk.id"></jofemar-nuuk-detail-form-setting>
    </div>
    <vdr-page-detail-sidebar>
        <div *ngFor="let jofemarNuuk of jofemarNuukCollection$ | async">
            <vdr-card [title]="jofemarNuuk?.name" class="mb-3">
                <div *ngIf="jofemarNuuk?.faultCodes.length !== 0" class="mb-2">
                    <div *ngFor="let faultCode of jofemarNuuk.faultCodes" class="my-1">
                        <clr-alert
                            [clrAlertType]="
                                faultCode === 'DoorDispenserModuleOpened' ||
                                faultCode === 'DoorCollectingModuleOpened'
                                    ? 'warning'
                                    : 'danger'
                            "
                            [clrAlertAppLevel]="true"
                            [clrAlertClosable]="false"
                        >
                            <clr-alert-item>
                                <span class="alert-text">{{
                                    'agromaat.agrofreeze.error.' + faultCode | translate
                                }}</span>
                            </clr-alert-item>
                        </clr-alert>
                    </div>
                </div>
                <vdr-page-entity-info [entity]="jofemarNuuk" />
                <div class="my-1" *ngIf="jofemarNuuk.availability !== null">
                    <label>{{ 'agromaat.agrofreeze.availabilityStatus' | translate }}</label>
                    <div class="mt-1 flex" style="gap: 12px">
                        <vdr-chip colorType="success" *ngIf="jofemarNuuk.availability === 'MACHINE_FREE'">
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>{{ 'agromaat.agrofreeze.availabilityMachineFree' | translate }}</span>
                        </vdr-chip>
                        <vdr-chip colorType="warning" *ngIf="jofemarNuuk.availability === 'MACHINE_BUSY'">
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>{{ 'agromaat.agrofreeze.availabilityMachineBusy' | translate }}</span>
                        </vdr-chip>
                        <vdr-chip colorType="warning" *ngIf="jofemarNuuk.availability === 'DISPENSING'">
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>{{ 'agromaat.agrofreeze.availabilityDispensing' | translate }}</span>
                        </vdr-chip>
                        <vdr-chip
                            colorType="warning"
                            *ngIf="jofemarNuuk.availability === 'ELEVATOR_COLLECTING_POSITION'"
                        >
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>{{
                                'agromaat.agrofreeze.availabilityElevatorCollectingPosition' | translate
                            }}</span>
                        </vdr-chip>
                        <vdr-chip
                            colorType="warning"
                            *ngIf="jofemarNuuk.availability === 'PRODUCT_DETECTOR_BLOCKED'"
                        >
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>{{
                                'agromaat.agrofreeze.availabilityProductDetectorBlocked' | translate
                            }}</span>
                        </vdr-chip>
                    </div>
                </div>
                <div class="mb-1">
                    <label>{{ 'agromaat.agrofreeze.connectionStatus' | translate }}</label>
                    <div class="mt-1 flex" style="gap: 12px">
                        <vdr-chip colorType="success" *ngIf="jofemarNuuk.connectionStatus === 'ONLINE'">
                            <clr-icon shape="success-standard"></clr-icon>
                            <span>Online</span>
                        </vdr-chip>
                        <vdr-chip colorType="error" *ngIf="jofemarNuuk.connectionStatus === 'OFFLINE'">
                            <clr-icon shape="is-solid"></clr-icon>
                            <span>Offline</span>
                        </vdr-chip>
                    </div>
                </div>
            </vdr-card>
        </div>
    </vdr-page-detail-sidebar>
</vdr-page-detail-layout>
